import { useRequestWithAuth } from "../../customHooks/useRequestWithAuth";

export const useCouponCode = (checkurl) => {
  const { doActualPostRequest } = useRequestWithAuth();
  const execute = async (params = {}) => {
    return doActualPostRequest(checkurl, null, null, { ...params })
      .then((res) => res)
      .catch((error) => error.response);
  };
  return execute;
};

export const generateOrderId = () => {
  const constant = 16;
  let suffix = Math.random().toString();
  let aSuffit = suffix.substring(suffix.length - 4, suffix.length);
  let placeholder = Date.now().toString();
  let numberId = placeholder + aSuffit;

  let newValue = "";
  for (let i = 0; i < constant; i++) {
    newValue += decide(numberId[i]);
  }

  return newValue;
};

const decide = (item) => {
  let decision = Math.floor(Math.random() * 3);
  if (decision === 0) {
    return String.fromCharCode(parseInt(item) + "A".charCodeAt(0));
  } else if (decision === 1) {
    return String.fromCharCode(parseInt(item) + "a".charCodeAt(0));
  } else {
    return item;
  }
};
