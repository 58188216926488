import React from "react";
import {Link} from "react-router-dom";


const CustomLink  = ({to="",
                      className="",
                      onClick=()=>{},
                      disbale=false,
                      children})=>{
    return (
        disbale?
        (<span className={`text-pink-primary cursor-not-allowed ${className}`}>
              {children}
         </span>)
            :
            (<Link to={to}
                   className={className}
                   onClick={onClick}>
          {children}
        </Link>)
    );
};

export default CustomLink;
