import formater from "date-fns/format";
export const isValidMobileNo = (no) => {
  let numberAsString;
  if (isNaN(parseInt(no)) === false) {
    numberAsString = Number(no).toString();
    if (
      numberAsString[0] !== "6" &&
      numberAsString[0] !== "7" &&
      numberAsString[0] !== "8" &&
      numberAsString[0] !== "9"
    ) {
      return "invalid mobile no";
    } else if (numberAsString.length < 10 || numberAsString.length > 10) {
      return "invalid mobile no";
    }
  } else {
    return "invalid mobile no";
  }
  return "none";
};

const canBeMobileNo = (no) => {
  if (isNaN(parseInt(no)) === false) {
    return true;
  } else {
    return false;
  }
};

export const isValidEmail = (email) => {
  const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const EMAIL_REGEX2 =
    /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  if (!email) {
    return "email can't be empty";
  } else if (
    email.match(EMAIL_REGEX) === null || email.match(EMAIL_REGEX2) === null
  ) {
    return "enter a valid email";
  } else {
    return "none";
  }
};

export const isValidPass = (password) => {
  if (password.length < 8) {
    return "Password must be at least 8 characters";
  } else if (password.search(/[a-z]/i) < 0) {
    return "Password must contain at least one letter";
  } else if (password.search(/(?=.*[A-Z])/) < 0) {
    return "Password must contain at least one uppercase";
  } else if (password.search(/[0-9]/) < 0) {
    return "Password must contain at least one digit";
  } else if (password.search(/(?=.*[!@#$%^&*])/) < 0) {
    return "Password must contain at least one special character";
  } else {
    return "none";
  }
};

export const isThisOnList = (toMatch, from) => {
  let x = from;

  let keys = Object.keys(x);

  let ret = keys.filter((val) => x[`${val}`].includes(toMatch) === true);

  if (ret.length === 0) {
    return "none";
  } else {
    return ret[0];
  }
};

export const isValidEmailOrMobile = (value) => {
  console.log(canBeMobileNo(value), "can be");
  if (canBeMobileNo(value) === true) {
    if (isValidMobileNo(value) === "none") {
      return "none";
    } else {
      return isValidMobileNo(value);
    }
  } else {
    if (isValidEmail(value) === "none") {
      return "none";
    } else {
      return isValidEmail(value);
    }
  }
};

export const getOperatorTextName = (op_code, list) => {
  const name = list.filter((ele) => ele.value == op_code);
  return name.length === 1 ? name[0]?.name : "";
};

export const formatDate = (stringDate = "", format = 1) => {
  console.log(stringDate, "sd");
  if (typeof stringDate === "string") {
    if (stringDate.trim().length === 0) {
      return "NA";
    }
  }
  if (stringDate === "") {
    return "NA";
  }
  if (stringDate === undefined) {
    return "NA";
  }
  if (stringDate === null) {
    return "NA";
  }
  // alert(stringDate)
  const list = stringDate?.split("/");
  let d = "";
  switch (format) {
    //dd-MM-yyyy
    case 1:
      d = `${list[2]}/${list[1]}/${list[0]}`;
      break;
    //yyyy-mm-dd
    case 2:
      d = `${list[0]}/${list[1]}/${list[2]}`;
      break;

    default:
      d = `${list[2]}/${list[1]}/${list[0]}`;
  }
  console.log(d, "st");
  let dataToReturn = "";
  try {
    dataToReturn = formater(new Date(d), "dd MMM yyyy");
    if (dataToReturn.includes("invalid") || dataToReturn.includes("Invalid")) {
      dataToReturn = "NA";
    }
  } catch (e) {
    dataToReturn = "NA";
  }
  return dataToReturn;
};

export const isAcceptablePassword = (password) => {
  if (password.length < 8) {
    return "Password must be at least 8 characters";
  } else if (password.search(/[a-z]/i) < 0) {
    return "Password must contain at least one letter";
  } else if (password.search(/[0-9]/) < 0) {
    return "Password must contain at least one digit";
  } else {
    return "none";
  }
};

export function checkFileType(byteArray) {
  const pngSignature = [0x89, 0x50, 0x4E, 0x47];
  const jpgSignature = [0xFF, 0xD8, 0xFF, 0xE0];
  const jpegSignature = [0xFF, 0xD8, 0xFF, 0xE1];
  const pdfSignature = [0x25, 0x50, 0x44, 0x46];

  if (compareByteArray(byteArray, pngSignature)) {
    return "image/png";
  } else if (
    compareByteArray(byteArray, jpgSignature) ||
    compareByteArray(byteArray, jpegSignature)
  ) {
    return "image/jpeg";
  } else if (compareByteArray(byteArray, pdfSignature)) {
    return "application/pdf";
  }

  // If the signature doesn't match any of the supported types
  return "unknown";
}

function compareByteArray(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function getNumberInStringOrError(size,fileName) {
  let postfix = "";
  let numberSize = 0;
  if (size / 1024 >= 1024) {
    postfix = "MB";
    numberSize = size / (1024 * 1024);
  } else {
    postfix = "KB";
    numberSize = size / 1024;
  }
  
  numberSize = numberSize.toFixed(2)
  if (size / (1024 * 1024) > 15) {
    return {
      errorMessage: "file size cannot be more than 15MB",
      sizeWithName: `${fileName} ${numberSize} ${postfix}`,
    };
  } else {
    return {
      errorMessage: "",
      sizeWithName: `${fileName} ${numberSize} ${postfix}`,
    };
  }
}


export const copyToClipboard = (text,onSuccess=()=>{},onFailure=()=>{}) => {
    console.log("attempting to copy");
    navigator.clipboard.writeText(text).then(
      () => {
        // setMessageAndStatus({
        //   message: "Copied to clipboard",
        //   status: true,
        // });
        // showToast(true);
        onSuccess()
      },
      () => {
        // setMessageAndStatus({
        //   message: "Failed to copy",
        //   status: false,
        // });
        // showToast(true);
       onFailure()
      }
    );
  };
