//import "../App.css"
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DropMenu from "./dropMenu";
import LoginWrapper from "./LoginWrapper";
import LoginModal from "./userpages/loginModal";
import { useDispatch } from "react-redux";
import { clearAll } from "../app/features/prepaidPlansSlice";
import { useLoginModal } from "./userpages/useLoginModal";
import { USER_LOGGED_OUT_REASON, IDLE_LOGOUT_KEY } from "./constants";
import CustomLink from "./utility/CustomLink";
import UserLogggedOutDueToIdleModal from "./modals/SmallActionResultModal"
import { GeolocationContext } from "./context/GeoLocationContext";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actionFuncion, modalState, close } = useLoginModal();
  const [showIdleLoggedOutModal, setShowIdleLoggedOutModal] = useState(false);
  const location = useLocation();


  useEffect(() => {
    if (sessionStorage.getItem(IDLE_LOGOUT_KEY) === USER_LOGGED_OUT_REASON) {
      setShowIdleLoggedOutModal(true);
      sessionStorage.setItem(IDLE_LOGOUT_KEY, "");
    }
  }, []);

  const isReferPage = location.pathname.includes("/signupreferred");
  console.log(isReferPage, "isReferPage");
  return (
    <>
      <header className="">
        <div className=" width mx-auto flex items-center mt-[3px] pl-1 ">
          {/* menu box */}
          <DropMenu gone={isReferPage ? "hidden" : ""} />

          <div
            className={`pl-[2px] pr-4 logo  sm:mr-auto relative  mt-[1px] msm:ml-2  small-logo shrink-[0.2] cursor-pointer ${isReferPage ? "msm:ml-none md:ml-4" : ""}`}
            tabIndex={0}
            onClick={() => {
              if (!isReferPage) {
                dispatch(clearAll());
                navigate("/home");
              }
            }}
            title="RechargeAXN"
          ></div>

          <div className={`nav flex gap-[0.9rem] items-center flat-menu hidden mlg:flex text-pink-primary`}>
            <CustomLink to="/home/referandearn" disbale={isReferPage}>
              <div className="rewards  text-sm">Refer</div>
            </CustomLink >
            <CustomLink to="/home/rewards" disbale={isReferPage}>
              <div className="rewards  text-sm">Rewards</div>
            </CustomLink>
            <CustomLink to="/home/offers" disbale={isReferPage}>
              <div className="offers  text-sm">Offers</div>
            </CustomLink>

            <CustomLink to="/home/suggestions" disbale={isReferPage}>
              <div className="suggestion text-sm">Complain</div>
            </CustomLink>
            {/* button for login/sign Up  shown when normal screen*/}
            <button
              className={`flex border-box h-9 rounded bg-primary font-normal  px-[5px] py-[4px] items-center  relative  -top-[2px] active:bg-pink-800 ${isReferPage ? "cursor-not-allowed invisible" : ""}`}
              title="Login / Sign Up"
              onClick={actionFuncion}
              disabled={isReferPage}
            >
              <span className="text-tertiary leading-[13px] text-[13px]">
                Login / Sign Up
              </span>
            </button>
            {/* button for login/sign Up  shown when normal screen end*/}
          </div>

          {/* button for login/sign Up  shown when small screen*/}
          <button
            className={`flex border-box rounded bg-primary font-normal  items-center p-[5px] relative show-small active:bg-pink-800 -top-[1px] block mlg:hidden h-[34px] msm:h-[36px] ${isReferPage ? "invisible" : ""}`}
            style={{ top: "-1px" }}
            title="Login / Sign Up"
            onClick={actionFuncion}
          >
            <span className="text-tertiary leading-[13px] text-[13px]">
              Login / Sign Up
            </span>
          </button>
          {/* button for login/sign Up  shown when small screen end*/}

          <LoginModal
            closeModal={() => close()}
            open={modalState}
            exClasses="px-3 py-3 pb-1"
          >
            <LoginWrapper />
          </LoginModal>
        </div>
      </header>
      <UserLogggedOutDueToIdleModal
        title="User Logged Out"
        message="You were logged out due to inactivity"
        open={showIdleLoggedOutModal}
        setOpen={() => setShowIdleLoggedOutModal(false)}
      />
    </>
  );
};
