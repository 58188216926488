import React from "react";
import Button from "../button"
const data = {
  heading: "Confirm Recharge",
  dataColumns: [
    {
      type: "n",
      bold: false,
      title: "Mobile No",
      value: "NIL",
    },
    {
      type: "n",
      bold: false,
      title: "Operator",
      value: "NIL",
    },
    {
      type: "n",
      bold: false,
      title: "Circle",
      value: "NIL",
    },
    {
      type: "p",
      bold: false,
      title: "Plan Description",
      value: "NIL",
    },
  ],
};

const ConfirmDetails = ({
  dataPlan = data,
  status = true, message = "",
  openDirectDialog = () => { },
  warningText = "If you know bill amount",
  buttonText='Pay Direct Bill'
}) => {
  return (
    <>
      {
        <>
          <div className="col-span-full w-full py-2 px-2 bg-gray-100 text-pink-primary font-medium  capitalize leading-[19px] max-h-12">
            {dataPlan.heading}
          </div>

          {status === false ? (<ErrorMessageBox
            warningText={warningText}
            buttonText={buttonText}
            message={message}
            openDirectDialog={openDirectDialog} />) : dataPlan.dataColumns.map((el) => (
            <>
              {/* title of data attribute */}
              <div
                className={`${el.bold ? "bg-gray-100 " : "text-gray-primary "}
                text-gray-primary font-semibold text-[13px]
                leading-[14px] px-4 py-2 ${el?.classesTitle}`}
              >
                {el.title}
              </div>
              {/* if it is a paragraph style its value differently */}
              {el.type === "p" ? (
                <p
                  className={`${el.bold ? "bg-gray-100" : "text-gray-primary "}
                  px-4 py-2  text-gray-primary leading-[15px]
                  col-span-full text-left text-xs ${el?.classesValue}`}
                >
                  {el.value}
                </p>
              ) : (
                <div
                  className={`${el.bold ? "bg-gray-100" : "text-gray-primary"}
                  px-1 pr-2 py-2 font-semibold text-gray-primary
                  text-right text-[13px] leading-[14px] ${el?.classesValue}`}
                >
                  {el.value}
                </div>
              )}
            </>
          ))}
        </>
      }
    </>
  );
};

export const ErrorMessageBox = ({ message = "", openDirectDialog = () => { },buttonText,warningText }) => {
  return (
    <div className="h-64 shadow-lg flex col-span-full mb-auto h-full text-center relative border border-pink-primary border-t-0">
      <span className="text-blue-600 font-medium absolute mb-auto mt-8 text-center w-full px-4">
        {message}
      </span>
      <div className="absolute top-[60%] flex items-center justify-center gap-2 w-full font-semibold mx-0">
        <span className="text-pink-primary">Click</span>
        <Button text={buttonText} click={openDirectDialog} />
      </div>
      <div className="absolute top-[70%] flex items-center justify-center gap-2 w-full m-4 mx-0">
        <span className="text-pink-primary font-semibold w-full text-center">{warningText}</span>
      </div>

    </div>
  )
}

export default ConfirmDetails;
