import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useTimer } from "use-timer";

const Toast = ({
  message = "",
  status = false,
  setOpen = () => console.log("helo"),
}) => {
  const { start, time } = useTimer({
    endTime: 0,
    initialTime: 2,
    timerType: "DECREMENTAL",
    onTimeOver: setOpen,
  });
  useEffect(() => {
    start();
    // return ()=>setOpen()
  }, []);

  return ReactDOM.createPortal(
    <motion.div
      class="flex space-x-2 justify-center fixed z-10 inset-0 bg-transparent text-white left-1/2  -translate-1/2 inset-0 pointer-events-none"
      initial={{ opacity: 0, y: "110vh" }}
      animate={{ opacity: 1, y: "80vh" }}
      exit={{ y: "110vh", opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div
        class="bg-white shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block h-fit"
        id="static-example"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-mdb-autohide="false"
      >
        <div
          class={`${
            status ? "bg-green-500" : "bg-red-error"
          } flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-gray-200 rounded-t-lg`}
        >
          <p class="font-bold text-white-500">Message</p>
          <div class="flex items-center">
            <p class="text-white-600 text-xs"></p>
            <button
              type="button"
              class=" btn-close box-content w-4 h-4 ml-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-mdb-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="p-3 bg-white rounded-b-lg break-words text-gray-700">
          {message}
        </div>
      </div>
    </motion.div>,
    document.querySelector("#notice")
  );
};

export default Toast;
