import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthFunctions } from "../../Auth/AuthFunctions";
import { BASE_ROUTE } from "../routes";
import { setOnlyJwt } from "../../app/features/loginManager";

export const useRequestWithAuth = () => {
  let response = "";
  const authHeader = useSelector(
    (state) => state.loginManager.jwtAndAuth.Token
  );
  const [token, setToken] = useState(authHeader);
  const [loading,setLoading] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {
    setToken(authHeader);
  }, [authHeader]);
  /*
  const [getResponse,setGetResponse] = useState({status:"pending",data:""})
  const [postResponse,setPostResponse] = useState({status:"pending",data:""})
  //value handler
  const handleSetPostRequest = (responseObject)=>{
    setPostResponse({status:responseObject.status,data:responseObject.data})
  }

  const handleSetGetRequest = (responseObject)=>{
    setGetResponse({status:responseObject.status,data:responseObject.data})
  }


  */
  //put request
  const newPutRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {},
    body = {},
    newRefreshToken = undefined
  ) => {
    //console.log(`Bearer ${token}`)
    return (response = axios
      .put(
        `${BASE_ROUTE}/${route}`,
        { ...body },
        {
          headers: {
            Authorization: `Bearer ${
              newRefreshToken === undefined ? token : newRefreshToken
            }`,
            ...extraHeaders,
          },
          params: {
            ...params,
          },
        }
      )
      .then((res) => res)
      .catch((error) => error.response));
  };
  // used to perform get requset with defalut request header

  const getRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {}
  ) => {
    console.log("");
    response = axios
      .get(`${BASE_ROUTE}/${route}${params == null ? "" : params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          ...extraHeaders,
        },
      })
      .then((res) => res.data)
      .catch((error) => error.response)
      .then((eres) => {
        if (eres.status === 412) {
          AuthFunctions(token, true).then(async (res) => {
            dispatch(setOnlyJwt(res.RefreshToken));
            // getRequsetWithAuth(route, params, extraHeaders)
            try {
              const res_1 = await axios.get(
                `${BASE_ROUTE}/${route}${params == null ? "" : params}`,
                {
                  headers: {
                    Authorization: `Bearer ${res.RefreshToken}`,
                    ...extraHeaders,
                  },
                }
              );
              return res_1.data;
            } catch (error) {
              return error.response;
            }
          });
        }
        return eres;
      });
    return response;
  };

  const postRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {},
    body = {}
  ) => {
    console.log(`Bearer ${token}`);
    response = axios
      .post(
        `${BASE_ROUTE}/${route}${params == null ? "" : params}`,
        { ...body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ...extraHeaders,
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => error.response)
      .then((eres) => {
        if (eres.status === 412) {
          AuthFunctions(token, true).then(async (res) => {
            dispatch(setOnlyJwt(res.RefreshToken));

            try {
              const res_1 = await axios.post(
                `${BASE_ROUTE}/${route}${params == null ? "" : params}`,
                { ...body },
                {
                  headers: {
                    Authorization: `Bearer ${res.RefreshToken}`,
                    ...extraHeaders,
                  },
                }
              );
              return res_1.data;
            } catch (error) {
              return error.response;
            }
          });
        }
        return eres;
      });

    return response;
  };

  //new implementations of get method
  const newGetRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {},
    newRefreshToken = undefined
  ) => {
    setLoading(true)
    // console.log(" ")
    return axios
      .get(`${BASE_ROUTE}/${route}`, {
        headers: {
          Authorization: `Bearer ${
            newRefreshToken === undefined ? token : newRefreshToken
          }`,
          ...extraHeaders,
        },
        params: {
          ...params,
        },
      })
      .then((res) => res)
      .catch((error) => error.response);
  };
  ///
  const newPostFromDataRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {},
    body = new FormData(),
    newRefreshToken = undefined
  ) => {
    //console.log(`Bearer ${token}`)
    return (response = axios
      .post(
        `${BASE_ROUTE}/${route}`,
        body ,
        {
          headers: {
            Authorization: `Bearer ${
              newRefreshToken === undefined ? token : newRefreshToken
            }`,
            "Content-Type": "multipart/form-data",
            ...extraHeaders,
          },
          params: {
            ...params,
          },
        }
      )
      .then((res) => res)
      .catch((error) => error.response));
  };
  //new implementation of post request with authentication header
  
  const newPostRequsetWithAuth = async (
    route,
    params = null,
    extraHeaders = {},
    body = {},
    newRefreshToken = undefined
  ) => {
    //console.log(`Bearer ${token}`)
    return (response = axios
      .post(
        `${BASE_ROUTE}/${route}`,
        { ...body },
        {
          headers: {
            Authorization: `Bearer ${
              newRefreshToken === undefined ? token : newRefreshToken
            }`,
            ...extraHeaders,
          },
          params: {
            ...params,
          },
        }
      )
      .then((res) => res)
      .catch((error) => error.response));
  };

  //

  const doActualFormDataPostRequest = async (
    route,
    params = null,
    extraHeaders = {},
    body = new FormData(),
    newRefreshToken = undefined,
    requireRequestToken = false
  ) => {
    let resp = await newPostFromDataRequsetWithAuth(
      route,
      params,
      extraHeaders,
      body,
      newRefreshToken
    );
    //if error
    if (resp.status === 412 || resp.status === 413) {
      //try refresh token refetching
      let refresh = await newGetRequsetWithAuth("refreshtoken", null, {
        isRefreshToken: true,
      }).catch((error) => error.response);

      console.log(refresh, "this is refresh before check");
      if (refresh.status === 200) {
        setToken(refresh.data.RefreshToken);
        dispatch(setOnlyJwt(refresh.data.RefreshToken));
      } else
        refresh = await newGetRequsetWithAuth("refreshtoken").catch(
          (error) => error.response
        );
      console.log(refresh, "this is refresh after check");

      if (refresh.status === 200) {
        let secondRes = await newPostFromDataRequsetWithAuth(
          route,
          params,
          extraHeaders,
          body,
          refresh.data.RefreshToken
        ).catch((error) => error.response);

        if (requireRequestToken) {
          return { token: refresh.data.RefreshToken, response: secondRes };
        } else {
          return secondRes;
        }
      }
    }

    //if no error return normal response
    else {
      if (requireRequestToken) {
        return { token: authHeader, response: resp };
      } else {
        return resp;
      }
    }
  };
  //testing version
  const doActualPostRequest = async (
    route,
    params = null,
    extraHeaders = {},
    body = {},
    newRefreshToken = undefined,
    requireRequestToken = false
  ) => {
    let resp = await newPostRequsetWithAuth(
      route,
      params,
      extraHeaders,
      body,
      newRefreshToken
    );
    //if error
    if (resp.status === 412 || resp.status === 413) {
      //try refresh token refetching
      let refresh = await newGetRequsetWithAuth("refreshtoken", null, {
        isRefreshToken: true,
      }).catch((error) => error.response);

      console.log(refresh, "this is refresh before check");
      if (refresh.status === 200) {
        setToken(refresh.data.RefreshToken);
        dispatch(setOnlyJwt(refresh.data.RefreshToken));
      } else
        refresh = await newGetRequsetWithAuth("refreshtoken").catch(
          (error) => error.response
        );
      console.log(refresh, "this is refresh after check");

      if (refresh.status === 200) {
        let secondRes = await newPostRequsetWithAuth(
          route,
          params,
          extraHeaders,
          body,
          refresh.data.RefreshToken
        ).catch((error) => error.response);

        if (requireRequestToken) {
          return { token: refresh.data.RefreshToken, response: secondRes };
        } else {
          return secondRes;
        }
      }
    }

    //if no error return normal response
    else {
      if (requireRequestToken) {
        return { token: authHeader, response: resp };
      } else {
        return resp;
      }
    }
  };
  //resting get request
  const doActualGetRequest = async (
    route,
    params = null,
    extraHeaders = {},
    newRefreshToken = undefined,
    requireRequestToken = false
  ) => {
    let resp = await newGetRequsetWithAuth(
      route,
      params,
      extraHeaders,
      newRefreshToken
    );
    //if error
    if (resp.status === 412 || resp.status === 413) {
      //try refresh token refetching
      let refresh = await newGetRequsetWithAuth("refreshtoken", null, {
        isRefreshToken: true,
      }).catch((error) => error.response);

      //console.log(refresh, "this is refresh before check");
      if (refresh.status === 200) {
        //setToken(refresh.data.RefreshToken);
        dispatch(setOnlyJwt(refresh.data.RefreshToken));
      } else
        refresh = await newGetRequsetWithAuth("refreshtoken").catch(
          (error) => error.response
        );
      //console.log(refresh, "this is refresh after check");

      //requst after refresh token success
      if (refresh.status === 200) {
        let secondRes = await newGetRequsetWithAuth(
          route,
          params,
          extraHeaders,
          refresh.data.RefreshToken
        ).catch((error) => error.response);
        setLoading(false)
        if (requireRequestToken) {
          return { token: refresh.data.RefreshToken, response: secondRes };
        } else {
          return secondRes;
        }
      }
    }
    //response
    else {
      setLoading(false)
      if (requireRequestToken) {
        return { token: authHeader, response: resp };
      } else {
        return resp;
      }
    }
  };

  const doActualPutequest = async (
    route,
    params = null,
    extraHeaders = {},
    body = {},
    newRefreshToken = undefined,
    requireRequestToken = false
  ) => {
    let resp = await newPutRequsetWithAuth(
      route,
      params,
      extraHeaders,
      body,
      newRefreshToken
    );
    //if error
    if (resp.status === 412 || resp.status === 413) {
      //try refresh token refetching
      let refresh = await newGetRequsetWithAuth("refreshtoken", null, {
        isRefreshToken: true,
      }).catch((error) => error.response);

      console.log(refresh, "this is refresh before check");
      if (refresh.status === 200) {
        setToken(refresh.data.RefreshToken);
        dispatch(setOnlyJwt(refresh.data.RefreshToken));
      } else
        refresh = await newGetRequsetWithAuth("refreshtoken").catch(
          (error) => error.response
        );
      console.log(refresh, "this is refresh after check");

      if (refresh.status === 200) {
        let secondRes = await newPutRequsetWithAuth(
          route,
          params,
          extraHeaders,
          body,
          refresh.data.RefreshToken
        ).catch((error) => error.response);

        if (requireRequestToken) {
          return { token: refresh.data.RefreshToken, response: secondRes };
        } else {
          return secondRes;
        }
      }
    }

    //if no error return normal response
    else {
      if (requireRequestToken) {
        return { token: authHeader, response: resp };
      } else {
        return resp;
      }
    }
  };

  //do actual get request
  return {
    getRequsetWithAuth: getRequsetWithAuth,
    postRequsetWithAuth: postRequsetWithAuth,
    newGetRequsetWithAuth: newGetRequsetWithAuth,
    newPostRequsetWithAuth: newPostRequsetWithAuth,
    doActualPostRequest: doActualPostRequest,
    doActualGetRequest: doActualGetRequest,
    doActualPutRequest: doActualPutequest,
    doActualFormDataPostRequest:doActualFormDataPostRequest,
    loading:loading
  };
};
