import { Header } from "./components/Header";
import React, { createContext,useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useState } from "react";
import { useSelector } from "react-redux";
//import "../admin/App.css"
import "../index.css";
import "./App.css";
import { Link, Outlet,useLocation } from "react-router-dom";
import { Footer } from "./components/Footer";
import Wrapper from "./components/wrapper";
import { HeaderLogged } from "./components/HeaderLogged";
import MainWrapper from "./components/MainWrapper";
import ErrorBoundary from "./components/ErrorBoundry";
import {LogoutContextProvider} from "./components/context/LogoutContext";
import { useUserInfoRequest} from "./Auth/UserRequests"

export const ModalContext = createContext();

function App() {
  const [modal, setModal] = useState(false);
  const [toggleMenu, setMenu] = useState(false);
  const isUserLogged = useSelector((state) => state.loginManager.isUserLogged);
  const {askUserInfo} = useUserInfoRequest()
  const location = useLocation()

  useEffect(()=>{
      if(isUserLogged){
          askUserInfo()
      }
  },[isUserLogged])
  const queryClient = new QueryClient();
  return (
    <ModalContext.Provider
      value={{
        modalToggle: () => setModal(!modal),
        toggleMenu: () => setMenu(!toggleMenu),
      }}
    >
      <QueryClientProvider client={queryClient}>
        <LogoutContextProvider>
        <div
          className={`m-0 p-0 front-root ${
            modal === true ? "h-screen overflow-hidden" : ""
          }`}
        >
          <MainWrapper>
            {location.pathname.includes("redirect") ===  true || sessionStorage.getItem("isIframe") === true? null:
              (isUserLogged ? <HeaderLogged /> : <Header />)}
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
              {location.pathname.includes("redirect") === true
                || location.pathname.includes("payment-gateway") === true
                || sessionStorage.getItem("isIframe") === true ?null:
              <Wrapper>
                <Footer />
              </Wrapper>}
          </MainWrapper>
        </div>
        </LogoutContextProvider>
      </QueryClientProvider>
    </ModalContext.Provider>
  );
}

export default App;

//{toggleMenu === true ? <HeaderLogged /> : <Header />}
