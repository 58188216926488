import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const SmallModal = ({
  closeModal=()=>{},
  open,
  runOnEnd = () => console.log(),
  noClose=false,
  width="",
  classOfParent="",
  children
}) => {
  useEffect(() => runOnEnd);

  if (!open) return null;
  return ReactDOM.createPortal(
    <div className="z-base max-h-[80vh]">
      <div className="fixed inset-0 bg-gray-900/70 z-base" onClick={() => closeModal()} />
      <div className={`p-6 fixed inset-0 top-[20%] md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 grid w-[96%] ${width === ""?" md:w-[20rem]":width} h-fit bg-white z-base`}>
        <div className="flex items-end h-2 w-full absolute top-7">
          <button className={`flex absolute -top-5 right-2 ${noClose?"hidden":""}`} onClick={() => closeModal()} >
            <i className={ "fa-solid fa-xmark text-gray-primary hover:text-pink-primary" } title="close"></i>
          </button>
        </div>
        <div className={`w-full h-full block ${classOfParent}`}>{children}</div>
      </div>
    </div>,
    document.querySelector("#portal")
  );
};

export default SmallModal;
