import React from "react";

export const NumberInput = ({
  Id = " ",
  name = "",
  extraClasses = " ",
  fieldClasses = " ",
  holder = " ",
  iType = "text",
  req = false,
  change = () => console.log("hello"),
  blur = () => console.log("blur"),
  val,
  maxlen = -1,
  numbersOnly = true,
  onleft = " ",
  color = "gray",
  title = "",
  dis = false,
  capitalAll=false,
  alphanumeric=false,
  sideItemNone=false
}) => {
  let defaultClasses =
    "border rounded-md text-black focus:text-pink-primary border-0 w-full outline-none text-[16px] leading-[21px] disabled:bg-gray-100 focus:text-pink-primary ";
    let defaultField = `flex border rounded items-center focus-within:border-1 p-1 min-h-[36px] max-h-[36px] ${
    dis ? "bg-gray-100 pointer-events-none " : ""
  }`;

  if (extraClasses !== " ") {
    defaultClasses += extraClasses;
  }

  if (fieldClasses !== " ") {
    defaultField += fieldClasses;
  }

  function changeHandle(e) {
    const v = capitalAll === true?e.target.value.toUpperCase():e.target.value
    let len = v.length;
    if (numbersOnly) {
      if (maxlen != -1) {
        if (maxlen > 0 && len <= maxlen) {
          change(
            v.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
          );
        }
      } else {
        change(
          v.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
        );
      }
    } else {
      if (alphanumeric) {
        change(
          v.replace(/[^0-9A-Za-z]/g, "")
        )
      } else {
        change(v);
      }
    }
  }

  function blurHandle(e) {
    blur(e);
  }
  return (
    <div className={defaultField} tabIndex={0} title={title} >
      <span
        className={`${color} mr-1 inline-block w-[max-content] text-md 
                   text-bold leading-[21px] roboto ${sideItemNone?"hidden":""}`}
        title={title}
      >
        {onleft}
      </span>
      <input
        disabled={dis}
        type={iType}
        name={name}
        id={Id}
        title={title}
        placeholder={holder}
        required={req}
        value={val}
        onBlur={blurHandle}
        onChange={changeHandle}
        className={defaultClasses}
      />
    </div>
  );
};
