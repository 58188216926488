import React from "react";

export const Input = ({
  focusFunction = () => console.log("focused"),
  blurFunction = () => console.log("blured"),
  handleKeyPress = () => console.log("kayPressed"),
  Id = " ",
  extraClasses = " ",
  holder = " ",
  iType = "text",
  change = () => console.log("changed"),
  name = "",
  val,
  numbersOnly = false,
  dis = false,
  maxlen = -1,
  override = {},
  alphanumeric = false,
  fullCaps = false,
  smallAll=false
}) => {
  let defaultClasses = `outline-pink-primary border rounded-md text-black field
  h-[36px] disabled:bg-gray-100 disabled:pointer-events-none
  text-base leading-[21px] ${extraClasses}
                        focus:text-pink-primary`;

  function changer(e) {
    let value = fullCaps ? e.target.value.toUpperCase() : e.target.value;
     value = smallAll ? e.target.value.toLowerCase() : e.target.value;
    let len = e.target.value.length;
    if (numbersOnly) {
      if (maxlen != -1) {
        if (maxlen > 0 && len <= maxlen) {
          change(
            value.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
          );
        }
      } else {
        change(
          value.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
        );
      }
    } else {
      if (alphanumeric) {
        change(
          value.replace(/[^0-9A-Za-z]/g, "")
        )
      } else {
        change(value);
      }

    }
  }

  function pressHandle(e) {
    handleKeyPress(e.target.value);
  }
  if (dis === true) {
    return (
      <input
        type={iType}
        id={Id}
        placeholder={holder}
        value={val}
        onChange={changer}
        onKeyPress={pressHandle}
        name={name}
        className={defaultClasses}
        disabled={dis}
        style={{ ...override }}
        onFocus={focusFunction}
        onBlur={blurFunction}
      />
    );
  } else {
    return (
      <input
        type={iType}
        id={Id}
        onKeyPress={pressHandle}
        placeholder={holder}
        value={val}
        onChange={changer}
        name={name}
        className={defaultClasses}
        style={{ ...override }}
        onFocus={focusFunction}
        onBlur={blurFunction}
      />
    );
  }
};
