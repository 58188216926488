import { useEffect, useState} from "react"
import { useLocation } from "react-router-dom"

const useGeolocation = ()=>{
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState({
    latitude:null,
    longitude:null
  })

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(
      (success)=>{
      setCurrentLocation({
        latitude:success.coords.latitude,
        longitude:success.coords.longitude
      })

    },error=>{
      console.log(error)
      setCurrentLocation({
        latitude:null,
        longitude:null
      })
    })

    window.navigator.geolocation.getCurrentPosition((success)=>{
      setCurrentLocation({
        latitude:success.coords.latitude,
        longitude:success.coords.longitude
      })

    },error=>{
      console.log(error)
      setCurrentLocation({
        latitude:null,
        longitude:null
      })
    })

  },[location.pathname])

  return {
     latitude:currentLocation.latitude,
     longitude:currentLocation.longitude
  }
}


export default useGeolocation
