import React, { useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import "../css/services.css";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_ROUTE } from "../components/routes";

const listStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "0 10.5px",
};
const Services = () => {

  const { isLoading, error, data, refetch } = useQuery(
    "serviceicons",
    () => axios.get(`${BASE_ROUTE}/serviceicons`).then((res) => res.data),
    { staleTime: Infinity }
  );

  const location = useLocation()
  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Some Error Happened</div>;
  }
  const showIconsInverted = (path,name)=>location.pathname.includes(path) || location.pathname.includes("postpaid") && name.includes("Mobile")
  return (
    <div className="min-h-[20px]">
      <ul className="service">
        {Object.keys(data).map((value, i) => {
          return (
            <li
              key={i}
              className=" text-center flex flex-col justiy-center text-pink-primary group"
              title={data[`${value}`].name}
            >
              <Link to={`${data[`${value}`].url}`} style={listStyle}>
            <div className={`flex justify-center items-center border border-pink-600 rounded-md w-[40px]
                            min-h-[40px] group-hover:text-white group-hover:bg-pink-primary ${showIconsInverted(data[`${value}`].url,data[`${value}`].name)?"text-white bg-pink-primary":""} `}>
                  <i className={data[`${value}`].fafaicon}></i>
                </div>
              </Link>
              <div className={`break-words w-[64px] leading-[11px] group-hover:font-semibold group-hover:text-pink-primary mt-1 text-secondary text-[11px] ${showIconsInverted(data[`${value}`].url,data[`${value}`].name)?"font-semibold text-pink-primary":""}`}>
                {data[`${value}`].name}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Services;
