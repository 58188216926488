import axios from "axios";
import {getUserAgent} from "../Auth/UserAgentParser"
import { BASE_ROUTE } from "./routes";
import { getClientIpAddress } from "../Auth/AuthFunctions";

export const logout = async (userName) => {
  const userAgent = getUserAgent()
  return getClientIpAddress().then(
    (ipClient) =>
      axios
        .post(`${BASE_ROUTE}/byesite`, {
          username: userName,
          ipaddress: ipClient,
          deviceinfo:userAgent.Deviceinfo,
          mode:"web"
        })
        .then((res) => res)
        .catch((error) => error.response)
    //return response
  );
};
