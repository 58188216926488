import React, { createContext } from "react";
import { logout as userLogout } from "../logout";

import { logOutUser } from "../../app/features/loginManager";
import { clearAll } from "../../app/features/prepaidPlansSlice";
import { setUserInfo } from "../../app/features/userInfoSlice";
import { useDispatch } from "react-redux";

export const LogoutContext = createContext("logout");

export const LogoutContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const logoutUser = (userId) => {
    dispatch(clearAll());
    dispatch(setUserInfo({}));
    dispatch(logOutUser());
    userLogout(userId).then(() => { }).catch(() => { });
  };

  return (
    <LogoutContext.Provider value={{ logout: (userId) => logoutUser(userId) }}>
      {children}
    </LogoutContext.Provider>
  );
};
