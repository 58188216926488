import Button from "../button"
import {useState} from "react"
import {useLoginModal} from "../userpages/useLoginModal"
import {useRequestWithAuth} from "../customHooks/useRequestWithAuth"
import Modal from "../modals/SmallActionResultModal"
const RedeemDescription = ({ src = "",desc="",value="",points="" }) => {
  const {doActualPostRequest} = useRequestWithAuth()
  const [show,setShow] = useState(false)
  const [message,setMessage] = useState({
    Status:"Failure",
    Message:""
  })

 const handleClick = ()=>{
  if(loginState){
      doActualPostRequest("user/buygift",{
        skucode:desc,
        username:info.username,
        mode:"web"
      }).then(res=>{
        // console.log(res.data)
          setMessage({...res.data})
          setShow(true)
      })
  }else {
    actionFuncion()
  }
}

const shouldDisableButton = ()=>{
  if(loginState && info?.rewardPoint < points){
    return true
  }else return false
}

 const {info,loginState,actionFuncion} = useLoginModal()
  console.log(info)
  return (
    <section className="flex flex-col lg:flex-row w-full col-span-full gap-8 bg-white">
      <div className="flex flex-col min-w-[12rem] lg:w-1/2 lg:max-w-[20rem]">
        <img src={src} className=" w-64 mx-auto my-2 lg:w-full" />
        <p className="text-center text-pink-primary">{desc}</p>
      </div>
      {/*description*/}
      <div className="grid grid-cols-12 border-2 border-gray-separator p-2 text-sm gap-2 h-fit">
        <span className="text-sm  text-pink-primary p-1 col-span-4">Giftcard Value</span>
        <span className="text-sm text-pink-primary p-1 col-span-8">Rs {value}</span>
        <span className="text-sm text-pink-primary p-1 col-span-4">
          Reward Points
        </span>
        <span className="text-sm text-pink-primary p-1 col-span-8">{points} pts</span>
        <span className="col-span-full  text-white text-md p-1 text-center bg-pink-primary font-semibold">
          Receiver Details
        </span>
        <span className="text-sm  p-1 col-span-3">Email </span>
        <span className="text-sm text-pink-primary p-1 col-span-9">{loginState?info?.email:"NA"}</span>
        <span className="text-sm  p-1 col-span-3">Mobile</span>
        <span className="text-sm text-pink-primary p-1 col-span-9">{loginState?info?.mobile:"NA"}</span>
        <Button dis={shouldDisableButton()} text={`Pay ${points} Points`} exClasses="col-span-full w-fit mx-auto" click={handleClick}/>

      </div>
      <Modal message={message.Message} title="Gift Card Status" status={message.Status === "Success"} open={show} setOpen={()=>setShow(false)}>
      {message}
      </Modal>
    </section>
  )
}

export default RedeemDescription
