import SimpleModal from "../userpages/simpleModal"
import { useRequestWithAuth } from "../customHooks/useRequestWithAuth"
import { useState, useEffect } from "react"


const DonationHistory = ({ open = false, closeModal = () => { } }) => {
	const [data, setData] = useState(false)
	const [loading,setLoading] = useState(true)
	const { doActualGetRequest} = useRequestWithAuth()

	useEffect(() => {
	if(open){
		doActualGetRequest("user/donatehistory")
			.then(res => {
				if (Object.keys(res?.data).includes("Message")) {
					console.log(res.data)
					setData({})
					setLoading(false)
					return
				}
				setData(res)
				setLoading(false)
			})
		}
	}, [open])

	let toDisplayData = ""
	if (loading == true || loading == false) {
		toDisplayData = "Loading..."
	}

	return (<SimpleModal open={open}
	closeModal={closeModal}
	size={true}
		exOverride={{ maxWidth: "34rem",height:"fit-content",maxHeight:"28rem" }}
		parentClass="h-fit min-h-[6rem] max-h-[20rem]"
		exClasses="-translate-y-1/2 top-1/2 md:h-[fit-content]]">

		{loading == false ? (
			<table className="w-full mt-2 text-xs slg:text-md max-h-[12rem] overflow-y-scroll relative">
				<thead className="w-full sticky top-0">
					<tr className="bg-pink-primary text-white font-medium py-1">
						<td className="w-1/12 text-center p-1">S.No</td>
						<td className="w-1/6 text-center p-1">Amount</td>
						<td className="w-1/2 text-center p-1">Date</td>
					</tr>
				</thead>
				<tbody className="max-h-24">
					{Object.keys(data).length === 0?(
							<tr>
								<td colSpan="4" className="p-8 text-pink-primary font-bold text-lg text-center">
									No Transaction Found
								</td>
							</tr>
					):Object.keys(data?.data).map((element, index) =>(
						<tr className={`${index % 2 === 0 ? "bg-slate-100" : "bg-slate-200 "} text-black`} key={index} >
							<td className="w-1/12 text-center p-1">{element}</td>
							<td className="w-1/6 text-center p-1">{data?.data[`${element}`].DonateAmount}</td>
							<td className="w-1/2 text-center p-1">{data?.data[`${element}`].Datetime}</td>
						</tr>
					))}
				</tbody>
			</table>
		) : (
			<div className="h-full flex">
				<span className="m-auto text-pink-primary">
					{data?.data !== undefined && "Message" in data?.data ? data.data.Message : toDisplayData}
				</span>
			</div>
		)}
	</SimpleModal>)
}

export default DonationHistory
