import React, { useEffect, useRef, useState } from "react";
import transaction from "../otherData/transaction.json";
import "../css/transaction.css";
import { useRequestWithAuth } from "./customHooks/useRequestWithAuth";
import { isArray } from "./utility/smallUtils";

const LoadingState = {
  ERROR: "error",
  DONE: "done",
  LOADING: "loading"
};


const TransactionList = ({ userId = 1 }) => {
  const [loading, setLoading] = useState(LoadingState.LOADING);
  const [data,setData] = useState({})
  let headers = [
    "Sr No",
    "Date",
    "Activity",
    "Debit",
    "Credit",
  ];
  const { doActualGetRequest } = useRequestWithAuth();

  // let dataRef = useRef();

  useEffect(() => {
    doActualGetRequest("user/rewardlist")
      .then(res => {
        if (res.status === 200 && (Object.keys(res.data).includes("Message") === false)) {
          setData(res.data);
          setLoading(LoadingState.DONE);
        }else {
          setData({})
          setLoading(LoadingState.DONE);
        }
      })
      .catch(() => {
        setLoading(LoadingState.ERROR);
      });
  }, []);


  if (loading === LoadingState.LOADING) {
    return (
      <div className="mt-1">
        Loading....
      </div>
    );
  }
  if (loading === LoadingState.ERROR) {
    return (
      <div className="mt-1">
        Oops some error happened.. Try later
      </div>
    );
  }

  return (
    <div className="tableGrid overflow-x-auto mt-1 ">
      {/* mapping over all header items */}
      {headers.map((header) => (
        <div className="text-white flex items-center justify-center text-sm lg:text-base bg-pink-primary h-8">
          <span className="font-bold text-sm">{header}</span>
        </div>
      ))}
      {Object.keys(data).length === 0 ? (
        <div className="col-span-full text-md font-medium text-center shadow-lg flex justify-center items-center text-pink-primary">
          <div>No transaction found</div>
        </div>
      ) : (
        <>
          {Object.keys(data).map((each, index) => (
            <>
              <div className="text-gray-800 flex items-center justify-center text-sm lg:text-base ">
                <span className="text-gray-primary text-xs lg:text-sm">
                  {index + 1}
                </span>
              </div>

              <div className="text-gray-800 flex items-center justify-center text-sm lg:text-base ">
                <span className="text-gray-primary text-xs lg:text-sm">
                  {data[`${each}`]["date"] ?? ""}
                </span>
              </div>
              <div className="text-gray-800 flex mx-4 items-center justify-left text-sm lg:text-base ">
                <span className="text-gray-primary  w-full text-xs lg:text-sm">
                  {data[`${each}`]["activity"] ?? ""}
                </span>
              </div>
              <div className="text-gray-800 flex items-center justify-center text-sm lg:text-base ">
                <span className="text-gray-primary text-xs lg:text-sm">
                  {data[`${each}`]["debit"] ?? ""}
                </span>
              </div>
              <div className="text-gray-800 flex items-center justify-center text-sm lg:text-base ">
                <span className="text-gray-primary text-xs lg:text-sm">
                  {data[`${each}`]["credit"] ?? ""}
                </span>
              </div>

              {/*
                <div className="text-gray-800 flex items-center justify-center text-sm lg:text-base ">
                  <span className="text-gray-primary text-xs lg:text-sm">
                    {each.totalPoint}
                  </span>
                </div>
                  */}
            </>
          )
          )}
        </>
      )}
    </div>
  );
};

export default TransactionList;
