import React, { useState, useEffect,useContext } from "react"
import ConfirmDetails from "./subServices/confirmDetails"
import WithTextInput from "./withTextInput"
import Failuretemplatemodal from "./userpages/failureTemplateModal"
import { useRequestWithAuth } from "./customHooks/useRequestWithAuth"
import Checkbox from "react-custom-checkbox"
import { useCouponCode } from "./userpages/wallet/networkFunctions"
import Button from "./button"
import { useUserInfoRequest } from "../Auth/UserRequests"
import { NonCloseableModal } from "./userpages/nonCloseableModal"
import PleaseWaitModal from "./subServices/PleaseWaitModal"
import SmallModal from "./userpages/smallModal"
import { useNavigate } from "react-router-dom"
import { useTimer } from "use-timer"
import {
  COUPON_APPLY_STATE,
  COUPON_CODE_APPLY_MESSAGES,
} from "./constants"
import { useDebounce } from "react-use"
import { useLoginModal } from "./userpages/useLoginModal"
import { FaPencilAlt } from "react-icons/fa"
import {TypingContext} from "./home"
import { useOpenPaymentManager } from "./userpages/wallet/OpenMoneyPaymentManager"
import {ROUTE} from "./routes"
import IFrameDialog from "../../admin/components/UpiGatewayTesting/IFrameDialog"
import {getClientIpAddress} from "../Auth/AuthFunctions"
import { GeolocationContext } from "./context/GeoLocationContext"

const Card = ({
  cardConfirmList,
  exClasses = "",
  total = "0",
  checkUrl = "coupons/applyrechargecode",
  category = "",
  rechargeRequestUrl = "",
  requestData = {},
  serviceName = "prepaidMobile",
  status = true,
  message = "",
  allowEditValues = false,
  changeTotalValue = () => { },
  resetDetailsStatus = () => { }
}) => {
  const { latitude, longitude } = useContext(GeolocationContext)
  const [otp,setOtp] = useState(false)
  const [openWaitModal, setOpenWaitModal] = useState(false)
  const { askUserInfo } = useUserInfoRequest()
  const [have, setHave] = useState(false)
  const [promo, setPromo] = useState("")
  //   is allowed to apply coupon i.e. if from wallet then not else yes
  const {writing,setWriting} = useContext(TypingContext)
  const navigate = useNavigate()
  const [allowed, isAllowed] = useState(true)
  const [open, setOpen] = useState(false)
  const [showEditBox, setShowEditBox] = useState(true)
  const [errorTitle, setErrorTitle] = useState(
    "Your Transaction was unsuccessful"
  )
  const [errorMessage, setErrorMessage] = useState(" ")
  const [shouldNotClickAgain, setShouldNotClickAgain] = useState(false)
  const [couponError, setCouponError] = useState("")
  const [applyState, setApplyState] = useState(COUPON_APPLY_STATE.BLANK)
  const { doActualPostRequest, doActualGetRequest } = useRequestWithAuth()
  const [directPay, setDirectPay] = useState(false)
  const checkCoupon = useCouponCode(checkUrl)
  const handleFailure = (info) => {
    console.log(info, "this is info")
    setErrorTitle("Your Transaction Was unsuccessful")
    setErrorMessage(info?.RESPMSG)
    setOpen(true)
  }
  const [iframeState,setIframeState] = useState({url:"",open:false})
  // const { initiateTranaction } = useServicePaymentManager((info) =>
  //   handleFailure(info)
  // )


  const { startPayment } = useOpenPaymentManager((info) =>
    handleFailure(info)
  )
  const { actionFuncion, loginState, info: userInfo } = useLoginModal()

  useEffect(() => {
    askUserInfo()
  }, [loginState])

  useDebounce(
    () => {
      console.log(applyState)
      if (applyState === COUPON_APPLY_STATE.NOT_APPLIED) {
        setApplyState(COUPON_APPLY_STATE.BLANK)
        setCouponError(COUPON_APPLY_STATE.BLANK)
      }
    },
    10000,
    [applyState]
  )

  const isValidNumber = (number) => {
    const temp = number?.match(/^([0-9]{0,15})(?!\.)$|^([0-9]{1,15})(\.{1})([0-9]{0,5})?$/g)
    if (temp?.length === 0) {
      return false
    } else return true
  }

  const { start, time, reset } = useTimer({
    initialTime: 23,
    endTime: 0,
    timerType: "DECREMENTAL",
  })

  const handleDirectPay = () => {
    if (total === "") {
      changeTotalValue(0)
    }
    setDirectPay(false)
    setShowEditBox(false)
    resetDetailsStatus()
  }

  const handleCouponApply = () => {
    if (promo === "") {
      setCouponError("Empty couponcode!!")
      setApplyState(COUPON_APPLY_STATE.ERROR)
      return
    }
    setCouponError("")
    let requestBody = {
      username: userInfo.username,
      code: promo,
      category: category,
      mode: "web",
      rechargeamount: `${total}`,
      amountfromwallet: "0",
      amountfrompaygateway: `${giveAppropriateValue(
        userInfo.walletBalance,
        true
      )}`,
    }

    checkCoupon(requestBody)
      .then((res) => {
        console.log(res,"rrrr")
        if (res.status >= 400) {
          setCouponError(res.data.Message)
          setApplyState(COUPON_APPLY_STATE.NOT_APPLIED)
        } else if (res.status === 200) {
          setApplyState(COUPON_APPLY_STATE.APPLIED)
          setCouponError("")
          setOtp(true)
        }
      })
      .catch((error) => {
        console.log(error.Message)
      })
  }

  const getCurrentApiName =async ()=>{
    return doActualGetRequest("user/pgname")
    .then(res=>res.data)
    .catch(error=>error.response?.data)
  }

  const handleCouponForAutoApply = () => {
    return checkCoupon({
      username: userInfo.username,
      code: promo,
      category: category,
      mode: "web",
      rechargeamount: `${total}`,
      amountfromwallet: "0",
      amountfrompaygateway: `${giveAppropriateValue(
        userInfo.walletBalance,
        true
      )}`,
    })
  }

  const setCls = () => {
    let x = " "
    setApplyState(COUPON_APPLY_STATE.NOT_APPLIED)
    setPromo(x)
    setOtp(false)
  }

  const shouldCallInitiateTransactionAPI = (
    walletBalance,
    totalBalance
  ) => {
    let shouldCallInitiateTransaction = false
    if (!allowed && walletBalance >= totalBalance) {
      //don't call initiateTranaction()
      shouldCallInitiateTransaction = false
    } else if (!allowed && totalBalance > walletBalance) {
      //call initiateTranaction()
      shouldCallInitiateTransaction = true
    } else if (allowed) {
      shouldCallInitiateTransaction = true
      //call initiateTranaction()
    }
    return shouldCallInitiateTransaction
  }

  const handlePromocodeChange = (value) => {
    setWriting(false)
    setPromo(value)
    setCouponError("")
  }

  useEffect(()=>{
   setCls()
  },[writing,allowed === false,total])

  const handlePaymentRequest = async () => {
    if (!loginState) {
      actionFuncion()
      return
    }
    if (total === "0" || total === "" || total === undefined || total === null || total == 0) {
      return
    }

    let potentialCouponApply = ""
    let dataFromCouponRequest = ""
    console.log("this is otp", otp)
    if (promo !== "") {
      potentialCouponApply = await handleCouponForAutoApply()
      if (potentialCouponApply.staus >= 400) {
        setApplyState(COUPON_APPLY_STATE.NOT_APPLIED)
        setCouponError("coupon not applied")
        isAllowed(false)
      }
      if (potentialCouponApply.ok) {
        dataFromCouponRequest = await potentialCouponApply.json()
      }
    }

    if (dataFromCouponRequest) setShouldNotClickAgain(true)
    const parsedTotal = parseFloat(total).toFixed(2)
    const parsedWalletBalance = parseFloat(userInfo.walletBalance)
    //when payment is made through wallet completely

    setShouldNotClickAgain(true)
    const shouldCallInitiateTransaction = shouldCallInitiateTransactionAPI(
      parsedWalletBalance,
      parsedTotal
    )
    const {pgname} = await getCurrentApiName()
    const ipClient = await getClientIpAddress()
    const response = await doActualPostRequest(
      rechargeRequestUrl,
      null,
      null,
      {
        amountfromwallet: !allowed
          ? parsedWalletBalance >= parsedTotal
            ? `${parsedTotal}`
            : parsedWalletBalance.toString()
          : "0",

        amountfrompaygateway: `${!allowed
            ? parsedWalletBalance >= parsedTotal
              ? 0
              : (parsedTotal - parsedWalletBalance).toFixed(2)
            : giveAppropriateValue(userInfo.walletBalance, true)
          }`,
        username: userInfo.username,
        code: !allowed && dataFromCouponRequest === "" ? "" : promo,
        mode: "web",
        pgname:pgname,
        ipaddress: ipClient,
        geolocation:`${latitude} ${longitude}`,
        siteinfo:ROUTE.includes("www")?"www":"notwww",
        ...requestData,
      },
      undefined,
      true
    )

    let responseData = response.response.data
    if (responseData["Status"] === "Error" || response?.response?.status >= 400) {
      setErrorMessage(responseData["Message"]??"")
      setErrorTitle("Server error")
      setOpen(true)
      setShouldNotClickAgain(false)
      return
    }

    if (!shouldCallInitiateTransaction) {
      setOpenWaitModal(true)
      reset()
      start()
    }

    let token = response.token
    console.log(responseData)
    if (shouldCallInitiateTransaction) {
      sessionStorage.setItem("oID",responseData.orderid)
       //for UPIGATEWAY --- yes it is a service provider 
      if(Object.keys(responseData).includes("paymenturl")){
        sessionStorage.setItem("oID",responseData?.orderid)
        sessionStorage.setItem("redirector",serviceName);
        setIframeState({url:responseData?.paymenturl,open:true})
        return;
      }
      //for open money
      startPayment(
        responseData.checksum,
        responseData.orderid,
        (val) => {
          setOpenWaitModal(true)
          reset()
          start()
          setTimeout(() => {
            sendSuccessDataToServer(val, token)
          }, 15000)
        },
        () => setShouldNotClickAgain(false),
      )
    } else {
      reset()
      start()
      setTimeout(() => {
        //setDataToSend({ ...responseData });
        if (responseData.Status === "Success") {
          doActualGetRequest(
            `user/orderenquiry/${responseData.orderid}`,
            null,
            null,
            token
          )
            .then((res) => {
              setOpenWaitModal(false)
              reset()
              navigate("/home/confirm", {
                state: {
                  ...res.data,
                  redirector: serviceName,
                  orderId: responseData.orderid,
                },
              })
            })
            .catch((error) => {
              setOpenWaitModal(false)
              reset()
              navigate("/home/confirm", {
                state: {
                  ...error.response,
                  redirector: serviceName,
                  orderId: responseData.orderid,
                },
              })
            })
        } else {
          // do something
        }
      }, 15000)
    }
  }

  const sendSuccessDataToServer = (sendingData, token) => {

    if(sendingData.orderid != sessionStorage.getItem("oID")){
      return;
    }
    console.log(sendingData, "sending data is this ")
    doActualGetRequest(
      `user/orderenquiry/${sendingData.orderid}`,
      null,
      null,
      token
    )
      .then((resp) => {
        navigate("/home/confirm", {
          state: {
            ...resp.data,
            redirector: serviceName,
            orderId: sendingData.orderid,
          },
        })
      })
      .catch((error) => {
        navigate("/home/confirm", {
          state: {
            ...error.response,
            redirector: serviceName,
            orderId: sendingData.orderid,
          },
        })
      })
    sessionStorage.setItem("oID",""); 
  }

  const giveAppropriateValue = (walletBalance = 0, numberOnly = false) => {
    console.log(walletBalance, "wb")
    let newTotal
    let parsedWalletBalance = parseFloat(walletBalance)
    let parseTotal = parseFloat(total)
    if (isNaN(parseTotal)) {
      parseTotal = 0
    }
    if (!allowed && !numberOnly) {
      newTotal =
        parsedWalletBalance >= parseTotal 
          ? `${parseTotal} from wallet`
          : (parseTotal - walletBalance).toFixed(2)
    } else if (!allowed && numberOnly) {
      newTotal =
        parsedWalletBalance > parseTotal &&
          parsedWalletBalance !== parseTotal
          ? parseTotal.toFixed(2)
          : (parseTotal - walletBalance).toFixed(2)
    } else {
      newTotal = parseTotal.toFixed(2)
    }
    return newTotal
  }

  return (
    <>
      <div
        className={`grid grid-cols-2 w-full lg:w-[348px] ${!status ? "" : "border"
          }  mx-auto mt-4 md:mt-3 ${exClasses} `}
      >
        {/* card details section start*/}
        <ConfirmDetails
          allowDirectPayment={false}
          dataPlan={cardConfirmList}
          status={status}
          message={message}
          openDirectDialog={() => setDirectPay(true)}
        />
        {/* card details section end*/}

        {/* ammount showing section start */}
        {status === false ? null : (
          <>
            <div className="p-1 px-8 bg-gray-200 font-medium text-black text-left py-2 text-sm flex items-center">
              <span>
                Total Amount:
              </span>
            </div>
            <div className="py-1 px-1 bg-gray-200 font-medium text-black text-right">
              <div className="flex gap-1 items-center text-end">
                {allowEditValues ?
                  <WithTextInput
                    text={!showEditBox ? <FaPencilAlt /> : null}
                    type="tel"
                    val={total}
                    exClasses="w-[100%]"
                    disable={!showEditBox}
                    change={(value) => {
                      if (isValidNumber(value)) {
                        changeTotalValue(value)
                      }
                    }}
                    blur={() => {
                      if (total === "") {
                        changeTotalValue(0)
                      }
                      setShowEditBox(false)
                    }}
                    iconClasses="scale-50 mt-0 -top-2 -right-[0.55rem] "
                    textClick={() => {
                      if (total === "") {
                        changeTotalValue(0)
                      }
                      setShowEditBox(!showEditBox)
                    }}
                  /> : null}

                {!(allowEditValues) ?
                  <span className="ml-auto mr-2">Rs {total}</span> : null}
              </div>
            </div>
            {/* ammount showing section end*/}
            {/* from here */}
            {allowed === false ? null : (
              <>
                {/* Apply coupon section start*/}
                <div className="capitalize col-span-full text-xs mt-1 ">
                  <span
                    className="inline-block w-full text-center cursor-pointer hover:text-black text-sm text-blue-600 mb-2 "
                    onClick={() => setHave(!have)}
                  >
                    Apply Coupon code{" "}
                    <i
                      className={`fas fa-chevron-down text-xs mx-2 hover:text-black`}
                    ></i>
                  </span>
                  {/* Apply coupon input start */}
                  <div className="mx-auto flex items-center scale-90 w-3/5 gap-2 py-0">
                    {have ? (
                      <>
                        <WithTextInput
                          change={(value) => handlePromocodeChange(value)}
                          placeholder="Code"
                          fullCaps={true}
                          val={promo}
                          type="text"
                          maxlen={12}
                          alphanumeric={true}
                          disable={otp === true ? true : false}
                          text={ otp ? ( <i className="fa-solid fa-trash-can"></i> ) : " "}
                          textClick={setCls}
                          exClasses={`${otp ? "" : " mx-auto "} p-px`}
                          innerClasses="h-[30px] w-full "
                          iconClasses="scale-90 mt-0 -top-px right-2 m-1 "
                        />
                        {otp ? null : (
                          <Button
                            click={handleCouponApply}
                            text="Apply"
                            exClasses="h-[36px] px-2"
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                  {/* Apply coupon input end*/}
                </div>

                <div className="mx-auto col-span-full scale-90 w-3/5 text-blue-error text-center text-xs font-semibold mt-1">
                  {couponError}
                </div>
                {/* Apply coupon section end*/}
                <div
                  className={`${applyState === COUPON_APPLY_STATE.APPLIED
                      ? ""
                      : "hidden"
                    } col-span-full text-xs mt-1 text-green-600 text-center h-4`}
                >
                  {COUPON_CODE_APPLY_MESSAGES.APPLIED}
                </div>
              </>
            )}
            {/* to here  */}
            <div className="col-span-full flex mt-1 px-2">
              {/* wallet balance section start */}
              <div className="flex ml-2 items-center">
                {userInfo?.walletBalance != "0" ?
                  <Checkbox
                    borderWidth={1}
                    borderColor="#f5317c"
                    icon={
                      <i className="fa-solid fa-square-check text-pink-600"></i>
                    }
                    id="payFromWallet"
                    onChange={(value) => isAllowed(!value)}
                  /> : null}
                <label
                  htmlFor="payFromWallet"
                  className="ml-1 text-gray-primary text-sm"
                >
                  Wallet Balance
                </label>
              </div>
              <span className="ml-auto mr-2 text-gray-800 font-semibold">
                {userInfo.walletBalance === undefined ||
                  userInfo.walletBalance === ""
                  ? 0
                  : userInfo.walletBalance}
              </span>
            </div>
            {/* wallet balance section start */}

            {/* pay ammount button section start */}
            <div className=" col-span-full py-2">
              <Button
                text={
                  shouldNotClickAgain
                    ? "Please Wait"
                    : `Pay Rs ${giveAppropriateValue(
                      userInfo.walletBalance
                    )}`
                }
                dis={shouldNotClickAgain || total == 0}
                exClasses="w-full "
                fClasses="text-[13px] leading-[13px]"
                click={handlePaymentRequest}
              />
            </div>
          </>
        )}
        {/* pay ammount button section end*/}
      </div>
      <Failuretemplatemodal
        title={errorTitle}
        exClasses={{ height: "min-content" }}
        message={errorMessage}
        status={false}
        open={open}
        setOpen={() =>{
            setOpen(false) 
            if(errorMessage.includes("KYC")){
              navigate("/home/kyc")
            }
        }}
      />

      {!directPay ? null : (
        <SmallModal open={directPay} closeModal={() => setDirectPay(false)}>
          <div className="flex flex-col items-center justify-center w-full gap-2 px-4 py-2">
            <span className="text-start text-pink-primary font-bold">Enter your bill amount</span>
            <WithTextInput
            val={total ?? ""}
              type="tel"
              text=""
              change={(value) => {
                if (isValidNumber(value)) {
                  changeTotalValue(value)
                }
              }
              } />
            <Button text="Submit" click={handleDirectPay} />
          </div>
        </SmallModal>
      )}

      {!openWaitModal ? null : (
        <NonCloseableModal open={openWaitModal} noBackground={true}>
          <PleaseWaitModal timer={time} />
        </NonCloseableModal>
      )}
      {iframeState.open?
        <IFrameDialog 
          closeModal={()=>{
            setIframeState({url:"",open:false})
            setShouldNotClickAgain(false)
          }}
          open={iframeState.open}
          url={iframeState.url}
        />
        :null
      }
    </>
  )
}

export default Card
