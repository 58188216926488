import React, { useEffect, useRef } from "react";
import Button from "../button";
import { useTimer } from "use-timer";
import { Input } from "../input";
import { ErrorMessage, Form, Formik } from "formik";
import axios from "axios";
import { BASE_ROUTE } from "../routes";
import { useDispatch } from "react-redux";
import {
  changeUserLoginState,
  setJwtAndAuth,
  setReverifyEmail,
  setReverifyMobile,
  userId,
} from "../../app/features/loginManager";
import { useLoginModal } from "./useLoginModal";
import { motion } from "framer-motion";
import { getClientIpAddress } from "../../Auth/AuthFunctions";
import { getUserAgent } from "../../Auth/UserAgentParser";
import { useAutofill } from "../customHooks/useAutofill";
import { useUserInfoRequest } from "../../Auth/UserRequests";
//import { getUserInfo } from "../../Auth/UserRequests";

const TwoFactorOtp = ({ goto = () => console.log("presed"), userAuth }) => {
  // const toogle = useContext(ModalContext);
  const { autoFillotp } = useAutofill();
  const { askUserInfo } = useUserInfoRequest();
  const dispatch = useDispatch();
  const formRef = useRef("");
  const submitRef = useRef();
  const { close } = useLoginModal();
  const initialValues = {
    otpValue: "",
  };

  const timer = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
  });
  console.log(formRef.current);
  useEffect(() => {
    timer.start();
  }, []);

  useEffect(() => {
    if (
      autoFillotp.length === 6 && formRef.current.values.otpValue.length !== 6
    ) {
      formRef.current.setFieldValue("otpValue", autoFillotp, false);
      handleSubmit({ otpValue: autoFillotp }, formRef.current);
    }
  }, [autoFillotp]);

  const askOtp = async () => {
    return getClientIpAddress().then(async (ipClient) => {
      return axios
        .post(`${BASE_ROUTE}/getotp`, {
          identifier: userAuth.usernameUser,
          password: userAuth.passwordUser,
          servicename: "loginusr",
          mode: "web",
          ipaddress: ipClient,
        })
        .then((res) => res)
        .catch((error) => error.response);
    });
  };

  const submitOtp = async (values) => {
    let userAgent = getUserAgent();
    let response = "";
    response = getClientIpAddress().then(async (ipClient) => {
      return axios
        .post(`${BASE_ROUTE}/nextlogin`, {
          identifier: userAuth.usernameUser,
          otp: values.otpValue,
          servicename: "loginusr",
          ipaddress: ipClient,
          browserinfo: userAgent.Browserinfo,
          deviceinfo: userAgent.Deviceinfo,
          mode: "web",
          role:"USER"
        })
        .then((res) => res)
        .catch((error) => error.response);
    });

    return response;
  };

  const handleSubmit = (values, formikbag) => {
    console.log(values);
    submitOtp(values).then((res) => {
      console.log(res.status);
      if (res.status === 200) {
        if (res.data.Role != "USER") {
          formikbag.setFieldError("otpValue", "Unauthorized User");
          return;
        }
        dispatch(setJwtAndAuth(res.data));
        dispatch(changeUserLoginState(true));
        dispatch(userId(res.data.Username));
        askUserInfo().then(() => {
          if ("ReverifyEmail" in res.data) {
            dispatch(setReverifyEmail(res.data.ReverifyEmail));
            sessionStorage.setItem("reverifyEmail", res.data.ReverifyEmail);
          }
          if ("ReverifyMobile" in res.data) {
            dispatch(setReverifyMobile(res.data.ReverifyMobile));
            sessionStorage.setItem("reverifyMobile", res.data.ReverifyMobile);
          }
          // reverify check
          //check ends
          close();
        });
      } else {
        formikbag.setFieldError("otpValue", "Invalid OTP");
      }
    });
  };

  const resendOtp = () => {
    askOtp().then((resp) => {
      console.log(resp);
      if (resp.status === 200) {
        formRef.current.setFieldError("otpValue", "");
        timer.reset();
        timer.start();
      } else {
        formRef.current.setFieldError("otpValue", "some error happened");
      }
    });
  };

  const validate = (values) => {
    const errors = {};
    if (values.otpValue.length < 6) {
      errors.otpValue = "Invalid OTP";
    }
    if (values.otpValue.length === 6) {
      handleSubmit({ otpValue: values.otpValue }, formRef.current);
    }
    return errors;
  };

  return (
    <motion.div
      className="h-[17rem]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form className="h-[17rem]">
            <div className="w-full flex h-[15rem] flex-col">
              <div className="flex flex-1 flex-col gap-2 ">
                <span className="mx-auto pt-2 text-base text-gray-primary">
                  OTP sent to {userAuth.isTestUser ? "Email" : "Mobile & Email"}
                </span>
                {userAuth.isTestUser
                  ? (
                    <div className="flex flex-col items-center justify-center text-pink-primary">
                      <span className="w-full text-center text-xs font-bold">
                        Test user OTP is {userAuth.isTestUser}
                      </span>
                      <span className="text-xs ">(Only for App testing)</span>
                    </div>
                  )
                  : null}

                <div className="w-full text-center text-sm mt-auto text-blue-error">
                  <ErrorMessage name="otpValue" />
                </div>
                <Input
                  numbersOnly={true}
                  name="otpValue"
                  Id="otpValue"
                  holder="Enter OTP"
                  iType="tel"
                  maxlen={6}
                  val={formik.values.otpValue}
                  extraClasses="max-w-[10rem] mx-auto mb-auto"
                  change={(value) =>
                    formik.setFieldValue("otpValue", value, true)}
                  blurFunction={formik.handleBlur}
                />
                <div className="w-full ">
                  {timer.time !== 0
                    ? (
                      <>
                        If not receive OTP in
                        {Math.floor(timer.time / 60)}:{timer.time % 60} sec
                      </>
                    )
                    : (
                      <>
                        <a
                          href="#"
                          className="text-pink-primary text-sm"
                          onClick={() => resendOtp()}
                        >
                          Resend OTP
                        </a>
                        {/* <Button text="resend otp" click={() => resendOtp()} /> */}
                      </>
                    )}
                </div>
                <div className="w-full ">
                  <Button
                    ref={submitRef}
                    text="Submit"
                    type="submit"
                    exClasses="w-full active:bg-pink-900"
                  />
                </div>
              </div>
              <div className="text-center">
                <span onClick={() => goto("login")} className="cursor-pointer">
                  Back to Login
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};

export default TwoFactorOtp;
