import React, { useState, useRef, useEffect,useContext } from "react"
import Card from "../card"
import LoginModal from "../userpages/loginModal"
import LoginWrapper from "../LoginWrapper"
import { renderProvider } from "../../otherData/inputWithImage"
import SelectSearch, { fuzzySearch } from "react-select-search"
import { useFormik } from "formik"
import { NumberInput } from "../numberInput"
import { getRenderFormValue } from "./renderFormValue"
import operator from "../../otherData/postpaidOperator.json"
import { isValidMobileNo } from "../usefullFunctions"
//import postpaidConfirm from "./specialJsons/postpaidConfirm.json";
import "../../css/grids.css"
import { useRequestWithAuth } from "../customHooks/useRequestWithAuth"
import { postpaidConvertor } from "../customHooks/DetailsConverter"
import { useLoginModal } from "../userpages/useLoginModal"
import Button from "../button"
import axios from "axios"
import { Input } from "../input"
import { formatDate } from "../usefullFunctions"
import { SERVICE_NAME } from "../constants"
import {TypingContext} from "../home"
import {useMedia} from "react-use"
import {ErrorMessageBox} from "./confirmDetails"

let operatorList = operator.list.map((item) => ({
  name: item.op_name,
  value: item.op_key,
  photo: item.image,
  code: item.op_code,
}))

const PostpaidMobile = () => {
  console.log(operatorList, "list")
  const [otp, setOtp] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const ref = useRef({ phoneNo: "", operator: "" })
  let operatorRender = getRenderFormValue("operator")
  const [openModal, setOpenModal] = useState(false)
  const { loginState, actionFuncion } = useLoginModal()
  const [data, setData] = useState("")
  const { doActualPostRequest } = useRequestWithAuth()
  const [disable, setDisable] = useState(false)
  const [allowToEdit, setAllowToEdit] = useState(false);
  const {setWriting} = useContext(TypingContext)
  const isBigScreen = useMedia("(min-width:820px)")

  const changeTotal = (value) => {
    const newData = { ...data }
    newData["Bill Amount"] = value
    setData({ ...newData })
  }

  const resetStatus = () => {
    const temp = {}
    temp["Message"] = "Success"
    setAllowToEdit(true)
    setData(temp)
  }

  function getOperatorName(op_name) {
    let value = Object.keys(operator.list2).filter((element) =>
      operator.list2[`${element}`].includes(op_name)
    )
    let newOpertor = 0
    if ((value.length !== 0 && value !== undefined) || value !== null) {
      newOpertor = operator.list3[`${value[0]}`]
    }

    return newOpertor
  }
  function handleSubmit(value) {
    if (loginState) {
      submitForm(value)
    } else {
      actionFuncion()
    }
  }

  const getOperatorTextName = (op_code) => {
    const name = operatorList.filter((ele) => ele.value == op_code)
    return name.length === 1 ? name[0]?.name : ""
  }

  function submitForm(value) {
    setDisable(true)
    doActualPostRequest("user/getebill", {
      opcode: value.operator,
      custnum: value.phoneNo,
      service: "postpaidpay",
    }).then((res) => {
      setData(res.data)
      setShowCard(true)
      setDisable(false)
      //console.log(res.data, "this")
    })
  }

  const validate = (values) => {
    const errors = {}
    setWriting(true)
    if (values.phoneNo === "") {
      errors.phoneNo = "please enter a mobile no"
    }
    if (isValidMobileNo(values.phoneNo) !== "none") {
      errors.phoneNo = isValidMobileNo(values.phoneNo)
    }
    if (values.operator === "") {
      errors.operator = "select operator"
    }

    return { ...errors }
  }

  const formik = useFormik({
    initialValues: {
      phoneNo: "",
      operator: "",
    },
    validate,
    onSubmit: (value) => handleSubmit(value),
  })

  useEffect(() => {
    if (formik?.values?.phoneNo?.length === 10) {
      axios
        .get(
          `https://open-api.plansinfo.com/mobile/operator-circle?number=${formik.values.phoneNo}`
        )
        .then(function(response) {
          console.log("response", response.data)
          const operatorFromApi = getOperatorName(
            response.data?.data?.operator
          )
          if (operatorFromApi !== 0) {
            formik.setFieldValue("operator", operatorFromApi, true)
          }
        })
        .catch((error) => { })
    }
  }, [formik.values?.phoneNo])

  useEffect(() => {
    formik.setFieldValue("operator", "", false)
    formik.setFieldError("operator", "")
    formik.setFieldTouched("operator", false, true)
  }, [formik.values.phoneNo])

  useEffect(() => {
    setShowCard(false)
  }, [formik.values.operator])

  const setProps = (field, value) => {
    formik.setFieldValue(field, value)
  }

  //console.log(ref.current, "current")
  return (
    <div>
      <form
        className="grid grid-cols-1 gap-4 lg:gap-2 w-full mx-auto lg:grid-cols-5 "
        onSubmit={formik.handleSubmit}
      >
        <div className="flex flex-col h-auto w-full lg:max-w-[218px] min-w-[50px]">
          <NumberInput
            iType="tel"
            val={formik.values.phoneNo}
            blur={() => formik.setFieldTouched("phoneNo")}
            change={(value) => setProps("phoneNo", value)}
            maxlen={10}
            numbersOnly={true}
            onleft="+91-"
            id="phoneNo"
            holder="Mobile Number"
            // change={value => dispatch(storePhoneNo(value))}
            extraClasses=" text-sm text-base"
            fieldClasses="border-pink-600 focus:outline-none flex-1"
          />
          <span className="h-3 text-blue-error text-xs">
            {/* {isValid.mobileNo === "" || isValid.mobileNo === "none"
              ? null
              : isValid.mobileNo} */}
            {formik.errors.phoneNo && formik.touched.phoneNo
              ? formik.errors.phoneNo
              : null}
          </span>
        </div>
        <div className="flex flex-col h-auto w-full lg:max-w-[218px] min-w-[50px] ">
          <SelectSearch
            className="select-search "
            options={operatorList}
            renderOption={renderProvider}
            id="operator"
            value={formik.values.operator}
            filterOptions={fuzzySearch}
            renderValue={operatorRender}
            placeholder="Select Operator"
            onChange={(value) => {
              formik.setFieldTouched("operator")
              return formik.setFieldValue("operator", value, true)
            }}
          />

          <span className="h-3 text-blue-error text-xs">
            {/* {isValid.operator === "none" || isValid.operator === ""
              ? null
              : isValid.operator} */}
            {formik.errors.operator && formik.touched.operator
              ? formik.errors.operator
              : null}
          </span>
        </div>

        <Button
          dis={disable}
          text="Fetch Bill"
          type="submit"
          exClasses="min-w-[218px]"
          fClasses="text-[15px]"
        />
      </form>

      <LoginModal
        closeModal={() => setOpenModal(false)}
        open={openModal}
        exClasses="px-3 py-3 pb-1"
      >
        <LoginWrapper />
      </LoginModal>
      {/* bill display section */}

      <div className="grid grid-cols-1 items-center justify-center">
        {showCard ? (
          data["Message"] !== "Success" ? (
            isBigScreen?(
            <div className="h-fit shadow-lg flex items-center flex-col col-span-full relative gap-2 p-1">
              <span className="text-blue-error font-bold lg:relative top-5 lg:left-24 w-full mb-4 p-1">
                {data["Message"]}
              </span>
              <div className="flex lg:left-32 relative self-start items-center gap-2 px-1">
                <span className="text-pink-primary font-medium lg:font-semibold p-1">Click </span>
                <Button text="Pay Direct Bill" click={resetStatus} />
                <span className="text-pink-primary font-medium lg:font-semibold p-1">If you know bill Amount </span>
              </div>
            </div>
            ):(
              <ErrorMessageBox
                message={data["Message"]}
                buttonText="Pay Direct Bill"
                openDirectDialog={resetStatus}
                warningText="If you know bill amount"
                />
            )
          ) : (
            <Card
              cardConfirmList={postpaidConvertor(
                data["Consumer No"] ?? formik.values.phoneNo ?? "NA",
                data["Customer Name"] ?? "NA",
                data["Bill Amount"] ?? "NA",
                formatDate(data["Bill Due Date"], 2)
              )}
              otp={otp}
              setOtp={(value) => setOtp(value)}
              category="utility"
              msgCoupon="Coupon applied"
              applied={false}
              total={data["Bill Amount"] === ""?"":data["Bill Amount"]}
              serviceName={SERVICE_NAME.POSTPAID_MOBILE}
              rechargeRequestUrl="user/postpaidbill"
              requestData={{
                operatorcode: `${formik?.values?.operator}`,
                operatorname: `${getOperatorTextName(
                  formik?.values?.operator
                )}`,
                billamount: `${data["Bill Amount"] ?? "NA"}`,
                mobile: `${formik?.values?.phoneNo}`,
                servicename: "postpaidpay",
              }}
              allowEditValues={allowToEdit}
              changeTotalValue={allowToEdit ? (value) => changeTotal(value) : () => { }}
            />
          )
        ) : null}
        {/* confirm details section end*/}
      </div>
    </div>
  )
}

export default PostpaidMobile
