import {useState,useEffect} from "react"
export const useAutofill = () => {
	const [otpAutofill,setOtpAutofill] = useState("")
	useEffect(()=>{
	 if ('OTPCredential' in window) {
		    const abortController = new AbortController();
		    navigator.credentials.get({
		      otp: { transport:['sms']  },
		      signal: abortController.signal
		    })
		    .then(otp => {
			setOtpAutofill(otp.code);
			console.log(otp.code) 
		     })
		     .catch((error) => {
			     console.log(error,"some error happpened!!")
		     });
	}

	else{console.error("OTPCredential not supported")}
	},[])

	
	return {autoFillotp:otpAutofill} 
}

