import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/LoginSlice";
import overlayReducer from "./features/overlaySlice";
import prepaidPlansSlice from "./features/prepaidPlansSlice";
import loginOverlaySlice from "./features/loginOverlaySlice";
import loginManager from "./features/loginManager";
import userInfo from "./features/userInfoSlice";
//redux persist stuff
import storage from "redux-persist/lib/storage/session";
import {
  persistReducer,
  FLUSH,
  PURGE,
  REHYDRATE,
  PAUSE,
  PERSIST,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "frontkey",
  blacklist: ["prepaidPlan"],
  version: 1,
  storage,
};

const reducer = combineReducers({
  login: loginReducer,
  overlay: overlayReducer,
  loginManager: loginManager,
  userInfo: userInfo,
  prepaidPlan: prepaidPlansSlice,
});

const persisterdReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persisterdReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

//loginOverlay: loginOverlaySlice,
