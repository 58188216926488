import axios from "axios";
import { BASE_ROUTE } from "../components/routes";
import { useRequestWithAuth } from "../components/customHooks/useRequestWithAuth";
import { useDispatch } from "react-redux";
import { logOutUser } from "../app/features/loginManager"
import { setUserInfo } from "../app/features/userInfoSlice"
import { IDLE_LOGOUT_KEY, USER_LOGGED_OUT_REASON } from "../components/constants"
export const getUserInfo = (token) => {
	let response = axios
		.get(`${BASE_ROUTE}/userinfo`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((res) => res.data)
		.catch((error) => error.response);

	return response;
};

export const useUserInfoRequest = () => {
	const dispatch = useDispatch();

	const { doActualGetRequest } = useRequestWithAuth();
	async function askUserInfo() {
		return doActualGetRequest("userinfo")
			.then((res) => {
				if (res.status === 200) {
					if (res?.data?.status !== undefined) {
						dispatch(logOutUser())
						setUserInfo({})
						localStorage.setItem(IDLE_LOGOUT_KEY, USER_LOGGED_OUT_REASON)
					} else {
						if (checkRequestStatus(res?.data) == true) {
							dispatch(setUserInfo(res?.data))
              if ("reverifyEmail" in res.data) {
                sessionStorage.setItem("reverifyEmail",res.data.ReverifyEmail)
              }
              if ("ReverifyMobile" in res.data) {
                sessionStorage.setItem("reverifyMobile",res.data.ReverifyMobile)
              }
						} else {
							dispatch(logOutUser())
							setUserInfo({})
							localStorage.setItem(IDLE_LOGOUT_KEY, USER_LOGGED_OUT_REASON)
						}
					}
				}
			})
			.catch((error) => error);
	}

	return { askUserInfo: askUserInfo };
};

function checkRequestStatus(data = {}) {
	if (Object.keys(data).includes("username") == true) {
		return true
	} else {
		return false
	}
}
