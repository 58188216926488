import React, { useState, useEffect } from "react";
import { Tabs, useTabState, Panel } from "@bumaga/tabs";
import "../../css/planList.css";
import { useSelector, useDispatch } from "react-redux";
import { storetPlansInfo } from "../../app/features/prepaidPlansSlice";
import axios from "axios";
import opr from "../../otherData/operator.json";
import { useQuery } from "react-query";
import { BASE_ROUTE } from "../routes";
import operatorDetails from "../../otherData/operator.json";
// import circleList from "../../otherData/circle.json";
let operatorList = operatorDetails.list.map((item) => ({
  name: item.op_name,
  value: item.op_key,
  photo: item.image,
  code: item.op_code,
}));
const cn = (...args) => args.filter(Boolean).join(" ");
const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button
      className={cn(
        "tab text-left whitespace-nowrap border-b-2 border-white font-medium plansTab",
        isActive && "activePlan"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};


const MobileView = ({ close }) => {
  const [planTypes, setPlanType] = useState([]);
  const [plansList, setList] = useState([]);
  const opList = opr.list3;
  const [state, setState] = useState(0);
  const circleItem = useSelector((state) => state.prepaidPlan.circle);
  const operator = useSelector((state) => state.prepaidPlan.operator);
  const dispatch = useDispatch();
  const { isLoading, error, data, refetch } = useQuery(
    "repoData",
    () =>
      axios
        .get(
          `${BASE_ROUTE}/api/mplansparam/${opList[`${operator}`]}/${
            JSON.parse(circleItem).code
          }`
        )
        .then((res) => {
          let response = res.data;
          console.log(res);
          let x = response.categories.map((category) => category.name);
          setPlanType(x);
          let y = response.categories.map((category) => category.plans);
          setList(y);
          return res.data;
        }),
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const filterOperator = () => {
    let returnValue = operatorList.filter(
      (element) => element.value == operator
    );
    let operatorName = returnValue[0].name;
    return operatorName;
  };
  const handlePlanChoose = (e) => {
    let flatList;
    let plan;
    let receivedId = e.target.getAttribute("data-val");
    console.log(receivedId);
    if (receivedId) {
      flatList = plansList.flat(2);
      console.log(flatList);
      plan = flatList.filter((plan) => plan.id == receivedId);
      dispatch(storetPlansInfo({ ...plan[0] }));
      close();
      // dispatch(toggleOverlay())
    }
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>error happened</div>;
  if (data.status === "OK") {
    return (
      <Tabs state={[state, setState]}>
        <div className="grid grid-cols-12 w-full gap-2 mt-2">
          <h2 className=" stickey top-6 col-span-full font-medium leading-5 capitalize text-center text-pink-primary px-6">
            Recharge Plans of {filterOperator()} -{" "}
            {circleItem.length > 1 ? JSON.parse(circleItem).name : ""}
          </h2>
          <div className="col-span-full mx-auto w-11/12">
            {
              <div className="flex w-full lg:bg-gray-100 bg-white overflow-x-auto mx-auto justify-start md:justify-center">
                {planTypes.map((planType) => (
                  <Tab>{planType}</Tab>
                ))}
              </div>
            }
          </div>
          <div className="col-span-12">
            <hr className="mt-4" />

            <div className="flex flex-col h-screen text-sm text-gray-primary overscroll-none ">
              {plansList.map((plan) => (
                <Panel>
                  <div
                    className="grid items-center text-gray-primary pb-36 lg:p-1 lg:h-[28rem] "
                    onClick={handlePlanChoose}
                  >
                    <>
                      {plan.map((eachPlan) => (
                        <div class="text-gray-primay overflow-y-auto flex flex-col col-span-full w-full border-b items-left justify-between text-inherit">
                            <div class="flex">
                              <div class="flex flex-col">
                                <div class="p-1 text-gray-900 font-medium">
                                  <span className="text-pink-primary">Data:</span> {eachPlan.data ? eachPlan.data : "NA"}
                              </div>
                                <div class=" w-full p-1 text-xs text-gray-500 text-left leading-3">
                                  <span className="text-pink-primary">Validity:</span> {eachPlan.validity}
                                </div>
                              </div>
                              <div class="p-1 text-pink-primary flex  flex-col justify-center  items-center ml-auto">
                                <button 
                                class=" mx-auto border border-pink-primary w-[75px] hover:bg-pink-primary hover:text-white rounded text-inherit px-1" 
                                  data-val={eachPlan.id}>
                                 <span class="mx-auto text-inherit hover:text-white" data-val={eachPlan.id}>Rs {eachPlan.amount}</span>
                                </button>
                                <span class="text-center text-green-info text-xs pt-1">
                                {eachPlan.dailyCost === "" || eachPlan.dailyCost === undefined || eachPlan.dailyCost === null?(
                                   null
                                  ):(
                                     `Rs ${eachPlan.dailyCost}/day`
                                  ) }
                              </span>
                              </div>
                            </div>
                            <div class="p-1 pr-4 py-1 text-gray-primary text-[13px] leading-[13px]">
                              {eachPlan.benefit}
                            </div>
                        </div>
                      ))}
                      <div className="text-gray-primay flex h-8 col-span-full "></div>
                    </>
                  </div>
                </Panel>
              ))}
            </div>
          </div>
        </div>
      </Tabs>
    );
  } else {
    return <div>Invlid data</div>;
  }
};

export default MobileView;

// <div class="text-gray-primay overflow-y-auto flex flex-col col-span-full w-full border-b items-left justify-between text-inherit">
//     <div class="flex">
//       <div class="flex flex-col">
//         <div class="p-1 text-gray-900 font-medium">
//           Data: {eachPlan.data ? eachPlan.data : "NA"}
//       </div>
//         <div class=" w-full p-1 text-xs text-gray-500 text-left leading-3">
//           Validity: {eachPlan.validity}
//         </div>
//       </div>
//       <div class="p-1 text-pink-primary flex  flex-col justify-center  items-center ml-auto">
//         <button 
//         class=" mx-auto border border-pink-primary w-[75px] hover:bg-pink-primary hover:text-white rounded text-inherit p-1" 
//           data-val={eachPlan.id}>
//          <span class="mx-auto text-inherit hover:text-white" data-val={eachPlan.id}>Rs {eachPlan.amount}</span>
//         </button>
//         <span class="text-center text-green-info text-xs">
//           {eachPlan.dailyCost === "" || eachPlan.dailyCost === undefined || eachPlan.dailyCost === null?(
//            null
//           ):(
//              `Rs ${eachPlan.dailyCost}/day`
//           ) }
//       </span>
//       </div>
//     </div>
//     <div class="p-1 pr-4 py-1 text-gray-primary text-[13px] leading-[13px]">
//       {eachPlan.benefit}
//     </div>
//
// </div>
