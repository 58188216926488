import { useEffect } from "react";
import SimpleModal from "../userpages/simpleModal";
import Button from "../button";
import { FcApproval } from "react-icons/fc";
const SmallActionResultModal = ({
  message = "",
	size=true,
	height=true,
  status = false,
	closeOnBackgroudClick=false,
  title = "",
  open = false,
  setOpen = () => console.log(),
  exClasses ="",
	override={maxHeight:"9.25rem"},
  runOnEnd = () => console.log(),
}) => {
  useEffect(() => {
    return () => runOnEnd();
  }, []);
  if (open === false) return null;
  return (
    <SimpleModal
      size={size}
      open={open}
      height={height}
      closeOnBackgroudClick={closeOnBackgroudClick}
      closeModal={setOpen}
      exClasses={`px-1 py-1 h-min max-h-min max-w-[20rem] top-1/4 bottom-1/2 -translate-y-1/2 top-1/2 ${exClasses}`}
      exOverride={override}
    >
      <div className="h-full w-full flex flex-col justify-center items-center">
        <div className="text-center text-xl  p-0 pb-1 w-full flex items-center mt-6 justify-center">
          {status ? (
            <FcApproval size="2rem" />
          ) : (
            <img alt="alt" src="/error.png" className="w-10 h-10 " />
          )}

          <p
            className={`text-sm font-bold ${
              status == false ? "text-red-error" : "text-green-info"
            } w-3/4 pr-4`}
          >
            {title}
          </p>
        </div>

        <div className="text-center text-xl  p-0 px-3 pb-1">
          <p className="text-xs">{message}</p>
        </div>
        <div className="m-2">
          <Button text="OK" click={() => setOpen()} exClasses="px-4 py-1" />
        </div>
      </div>
    </SimpleModal>
  );
};

export default SmallActionResultModal;
