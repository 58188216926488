import React,{useState,useEffect} from "react";
import { useLocation } from "react-router-dom";
import { GeolocationContextProvider } from "./context/GeoLocationContext";
import Button from "./button";
import "../App.css";
import SmallModal from "./userpages/smallModal";
import { CircularProgress } from "@mui/material";
import useGeolocation from "./customHooks/useGeolocation"

const MainWrapper = (props) => {
  const locationPathIgnoreList = ["home/aboutus", "home/faq", "home/contactus", "home/terms", "home/refundpolicy", "home/privacypolicy"]
  const location = useLocation()
  const geolocation = useGeolocation()
  const [openModal, setOpenModal] = useState(false)

  const isInIgnoreList = (pathName)=>{
    const isInList = locationPathIgnoreList.filter(ele=>pathName.includes(ele))
    return isInList.length !== 0
  }

  useEffect(() => {
    if (geolocation.latitude === null || geolocation.longitude === null && (!isInIgnoreList(location.pathname))) {
      setOpenModal(true)
    }
  }, [location.pathname]);

  useEffect(() => {
    //console.log(geolocation)
    if (geolocation.latitude === null && !isInIgnoreList(location.pathname)) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }, [geolocation])

  return <div className="mainWrapper">
    <GeolocationContextProvider latitude={geolocation.latitude} longitude={geolocation.longitude}>
      {props.children}
      <SmallModal
        open={openModal}
        closeModal={() => {}}
      >
        <div className="font-md text-pink-primary text-center flex flex-col gap-4 items-center">

          Please allow location permission to continue accessing the site.
          {geolocation.loading ? (
            <CircularProgress color="secondary" />
          ):(
            <Button text="OK" exClasses="w-full"/>
          )}
        </div>
      </SmallModal>
    </GeolocationContextProvider >
  </div>;
};

export default MainWrapper;
