// Sandbox Credentials
export const useOpenPaymentManager = (onReject=()=>{}) => {

  const startPayment = (
    txnToken,
    orderId,
    onSuccess = () => {},
    runOnEnd = ()=>{}
  ) => {
    //new section
    window.Layer.checkout({
      token: txnToken,
      accesskey:localStorage.getItem("env_open") === "PROD"?
        "f75ab390-aa31-11ed-82bf-5b2d2ded8fdf" :
        "9caced20-a1ab-11ed-9785-8f47bd70fa14",
      theme: {
        logo: "https://rechargeaxn.com/pic/logo-site.jpeg",
        color: "#f5317c",
        error_color: "#0058ff"
      }
    },
      function(response) {

        if (response.status == "captured") {
          // response.payment_token_id
          // response.payment_id
	  console.error("I am getting called in lambda" , response)
          onSuccess({orderid:orderId,response:response}) 
          runOnEnd()
        } else if (response.status == "created") {
          runOnEnd()
        } else if (response.status == "pending") {


        } else if (response.status == "failed") {
          onReject({RESPMSG:"Payment rejected by user"})
          runOnEnd()
          //window.location.href = "failure_redirect_url";

        } else if (response.status == "cancelled") {
          onReject({RESPMSG:"Payment rejected by user"})
          runOnEnd()
          //window.location.href = "cancel_redirect_url";
        }
      },
      function(_) {
        //integration errors
      }
    );
    //new section end here
  }

  return {startPayment:startPayment}
};
