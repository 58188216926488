import React from "react";
//import { render, hydrate } from "react-dom"
import { render } from "react-dom";
import Front from "./frontend/front";
import "./index.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PromoRedirect from "./frontend/components/userpages/PromoRedirect";

const rootElement = document.getElementById("root");
const LazyAdmin = React.lazy(() => import("./admin/admin"));
render(

    <React.Suspense fallback="loading......">
      <BrowserRouter>
        <Routes>
          <Route path="/*">
            <Route index element={<Navigate replace to="/home/prepaid" />} />
            <Route path="home/*" element={<Front />} />
            <Route path="admin/*" element={<LazyAdmin />} />
            <Route path="market" element={<PromoRedirect/>}/>
            <Route path="market/:r" element={<PromoRedirect/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Suspense> ,
  rootElement
);

//)
/*
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement)
} else {
  render(<Root />, rootElement)
}
document.getElementById("root")
*/
