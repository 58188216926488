import { useEffect, useContext, createContext, useState } from "react";
import Wrapper from "./wrapper";
import NotificationBar from "./NotificationBar";
// import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Crosel } from "./Crosel";
import Services from "./Services";
import React from "react";
import ErrorBoundary from "./ErrorBoundry";
import { useLocation } from "react-router-dom";
import { LogoutContext } from "./context/LogoutContext";
import { useGeolocation } from "react-use";
export const TypingContext = createContext("coupon")

const Home = () => {
  const location = useLocation();
  const logout = useContext(LogoutContext);
  const [writing, setWriting] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    let path = "";
    if (!location.pathname.includes("/prepaid")) {
      return;
    }

    path = location.state;
    console.log(path, "path status");
    if (path !== null && Object.keys(path).includes("logout") && path.logout === true) {
      logout.logout(path.userId);
    }
  }, [location.pathname]);


  return (
    <>
      <Wrapper extraClass="mt-[0px]">
        <NotificationBar />
      </Wrapper>
      <Wrapper extraClasses="h-fit">
        <Crosel />
      </Wrapper>

      <Wrapper>
        <Services />
      </Wrapper>
      <TypingContext.Provider value={{ writing: writing, setWriting: (val) => setWriting(val) }}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </TypingContext.Provider>
    </>
  );
};

export default Home;
