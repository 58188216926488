import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Radio, RadioGroup, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearAll } from "../app/features/prepaidPlansSlice";

import "../css/radio.css";

const Prepaid = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //const [rechargeType, setRechargeType] = useState("prepaid");

  const handleRadioChange = (e) => {
    if (e.target.value === "prepaid") {
//      setRechargeType("prepaid");
      navigate("/home/prepaid", { replace: true });
    } else if (e.target.value === "postpaid") {
 //     setRechargeType("postpaid");
      dispatch(clearAll());
      navigate("/home/postpaid", { replace: true });
    }

    console.log(e.target.value);
  };

  // const handleEnter = (e) => {
  //   if (e.key === "Enter") {
  //     handleRadioChange(e);
  //   }
  // };

  return (
    <div className="w-full">
      <h2 className="font-medium leading-[19px] text-pink-primary flex items-center gap-1">
        <span> Mobile Recharge </span>
        <span className="ml-2 text-xs text-blue-error animation-bounce" title="Earn 2% Reward Points">(Earn 2% Reward Points)</span>
      </h2>
      <div className="flex gap-4 mb-2">
        <div className="flex items-center gap-2">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="prepaid"
            name="radio-buttons-group"
            row={true}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Radio
              value="prepaid"
              id="prepaid"
              onClick={handleRadioChange}
              style={{ padding: "1px", scale: ".75", color: "#f5317c" }}
              size="small"
            />
            <InputLabel
              htmlFor="prepaid"
              style={{
                fontSize: "14px",
                marginRight: "1rem",
                display:"flex",
                flexDirection:"row",
                alignItems:"center"
              }}
            >
              Prepaid
            </InputLabel>

            <Radio
              value="postpaid"
              id="postpaid"
              onClick={handleRadioChange}
              style={{ padding: "1px", scale: ".75", color: "#f5317c" }}
              size="small"
            />
            <InputLabel htmlFor="postpaid" style={{ fontSize: "14px" }}>
              Postpaid
            </InputLabel>
          </RadioGroup>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Prepaid;
