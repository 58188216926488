import { changeLoginScreenState } from "../../app/features/loginManager";
import { useSelector, useDispatch } from "react-redux";

export const useLoginModal = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.userInfo?.userInfo) ?? undefined;
  const loginManager = useSelector((state) => state?.loginManager?.jwtAndAuth) ?? undefined;
  const reverifyEmail = useSelector((state) => state?.loginManager.ReVerifyEmail)
  const reverifyMobile = useSelector((state) => state?.loginManager.ReVerifyMobile)
    //console.log(userInfo);
//?.referrerid
  const isUserLogged = useSelector((state) => state.loginManager.isUserLogged);
  const loginModalState = useSelector(
    (state) => state.loginManager.loginScreenStatus
  );

  const showLoginModalIfNotLogged = () => {
    if (isUserLogged === false && loginModalState === false) {
      dispatch(changeLoginScreenState(true));
      return true;
    } else {
      return false;
    }
  };

  const closeModal = () => {
    dispatch(changeLoginScreenState(false));
  };
  return {
    loginState: isUserLogged,
    modalState: loginModalState,
    actionFuncion: showLoginModalIfNotLogged,
    close: closeModal,
    info:userInfo,
    manager:loginManager,
    reverifyEmail:reverifyEmail,
    reverifyMobile:reverifyMobile
  };
};
