
import React, { useEffect } from "react";

import ReactDOM from "react-dom";
import {paddedModalSizeConstatns} from "../constants"

const PaddedModal = ({
  closeModal=()=>{},
  open,
  runOnEnd = () => console.log(),
  noClose=false,
  size=paddedModalSizeConstatns.md,
  children
}) => {
  useEffect(() => runOnEnd);
  let applySize = "max-w-[90vw] md:max-w-[20rem] " 
  switch(size){
    case paddedModalSizeConstatns.sm:
      applySize += "md:max-w-[18rem]"
      break;
    case paddedModalSizeConstatns.md:
      applySize += "md:max-w-[20rem]"
      break;
    case paddedModalSizeConstatns.lg:
      applySize += "md:max-w-[24rem]"
      break;
    case paddedModalSizeConstatns.xl:
      applySize += "md:max-w-[28rem]"
      break;
    case paddedModalSizeConstatns["2xl"]:
      applySize += "md:max-w-[32rem]"
      break;
  }

  if (!open) return null;

  return ReactDOM.createPortal(
    <div className="z-base max-h-[80vh]">
      {/*background dark overlay*/}
      <div
        className="fixed inset-0 bg-gray-900/70 z-base"
        onClick={() => closeModal()}
      ></div>
      {/*background dark overlay*/}

      <div className={`p-3 fixed inset-0 top-[20%] md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 grid w-full ${applySize} h-fit bg-white z-base rounded`}>
        <div className="flex items-end h-2 w-full absolute top-7">
          <button
            className={`flex absolute -top-5 right-2 ${noClose?"hidden":""}`}
            onClick={() => closeModal()}
          >
            <i className={"fa-solid fa-xmark text-gray-primary hover:text-pink-primary"} ></i>
          </button>
        </div>
        <div className="w-full h-full block">{children}</div>
      </div>
    </div>,
    document.querySelector("#portal")
  );
};

export default PaddedModal;
