import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const IFrameDialog = ({
  closeModal,
  open,
  url="",
  runOnEnd = () => console.log(),
}) => {
  useEffect(() => runOnEnd);

  if (!open) return null;

  return ReactDOM.createPortal(
    <div className="z-base max-h-[80vh]">
      <div className="fixed inset-0 bg-gray-900/70 z-base" ></div>
      <div className="p-6 fixed inset-0  left-1/2 -translate-x-1/2 grid w-[96%] md:w-[34rem] md:h-p[55rem] bg-white z-base">
        <div className="flex items-end h-2 w-full absolute top-7">
          <button
            className="flex absolute -top-5 right-2"
            onClick={() => closeModal()}
          >
            <i
              className={
                "fa-solid fa-xmark text-gray-primary hover:text-pink-primary"
              }
            ></i>
          </button>
        </div>
        <div className="w-full h-full block">
          <iframe src={url}  title="payemnt gateway" className="w-full h-full" />
        </div>
      </div>
    </div>,
    document.querySelector("#portal")
  );
};

export default IFrameDialog ;
