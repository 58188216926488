import React, { useState, useRef, useLayoutEffect } from "react"
import { createBreakpoint } from "react-use"
import { useClickAway } from "react-use"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useLogout } from "./customHooks/useLogout"
import { useLoginModal } from "./userpages/useLoginModal"

import { useUserInfoRequest } from "../Auth/UserRequests"
//defining  breakpoints for removal of hover action on user menu
const useBreakpoint = createBreakpoint({ XL: 1200, L: 820 })

const UserMenu = ({ showInSmall = false }) => {
  const nameOfUser = useSelector((state) => state.userInfo.userInfo.name)
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  useClickAway(ref, () => setOpen(false))
  const breakpoint = useBreakpoint()
  const { askUserInfo } = useUserInfoRequest()
  const { logUserOut } = useLogout()
  const { info } = useLoginModal()

  const closeMenu = () => {
    setOpen(false)
  }

  useLayoutEffect(() => {
    if (nameOfUser === undefined && open === true) {
      askUserInfo()
    }
  }, [open === true])

  return (
    <div className="relative bg-white" ref={ref}>
      <button
        className={`w-[33px] h-[33px] user bg-pink-primary mt-[6px] small-margin scale-small small-btn  ${showInSmall === true ? "show-in-small" : " "
          }`}
        onClick={() => setOpen(!open)}
        onMouseOver={breakpoint > "L" ? () => setOpen(true) : null}
      >
        <i
          className="fas fa-user text-white pointer-events-none inline-block"
          area-hidden="true"
        ></i>
      </button>
      <div
        className={`absolute  right-0  w-[200px] top-8 lg:top-10 z-base rounded border border-pink-primary bg-white ${open === true ? "block" : "hidden"
          }`}
      >
        <MenuBtn
          value={`Hi ${nameOfUser ?? "Loading..."}`}
          click={closeMenu}
          link="#"
          noBorder={info?.kycverified === "yes" ? true : false}
        />
        <MenuBtn
          value="My Profile"
          click={closeMenu}
          link="/home/profile"
          cls="text-left"
        />
        <MenuBtn
          value="My wallet"
          click={closeMenu}
          link="/home/wallet"
          cls="text-left"
        />
        <MenuBtn
          value="Offers"
          click={closeMenu}
          link="/home/offers"
          cls="text-left"
        />
        <MenuBtn
          value="Refer &amp; Earn"
          click={closeMenu}
          link="/home/referandearn"
          cls="text-left"
        />
        {info?.kycverified === "no" ? (
          <MenuBtn
            value="Aadhaar KYC"
            click={closeMenu}
            link="/home/aadhar"
            cls="text-left"
          />
        ) : null}

      {info?.blockedamount ?(
        <MenuBtn
          value="Blocked Amount"
          click={closeMenu}
          link="/home/blocked_amount"
          cls="text-left"
        />
      ):null }

        <MenuBtn
          value="Write Issue/Complain"
          click={closeMenu}
          link="/home/suggestions"
          cls="text-left"
        />
        <MenuBtn
          value="Add Address"
          click={closeMenu}
          link="/home/address"
          cls="text-left"
        />
        <MenuBtn
          value="Redeem e-GiftCard"
          click={closeMenu}
          link="/home/redeemgift"
          cls="text-left"
        />
        <MenuBtn
          value="Logout"
          click={logUserOut}
          cls="text-left"
          noLink={true}
        />
      </div>
    </div>
  )
}

const MenuBtn = ({
  value,
  click,
  link = "#",
  cls,
  noLink = false,
  noBorder = false,
}) => {
  if (noLink) {
    return (
      <span className="w-full m-0 ">
        <button
          className={`w-full p-1 text-sm bg-white text-pink-primary hover:text-white border border-gray-separator hover:bg-pink-primary rounded active:bg-pink-primary active:text-white  ${cls}`}
          onClick={() => click()}
        >
          {value}
        </button>
      </span>
    )
  }

  return (
    <Link to={link} className="w-full m-0 flex flex-col group">
      <button
        className={`w-full p-1 text-sm bg-white text-pink-primary group-hover:text-white ${noBorder ? "" : "border border-gray-separator"
          }  group-hover:bg-pink-primary rounded group-active:bg-pink-primary group-active:text-white  ${cls}`}
        onClick={() => click()}
      >
        {value}
      </button>

      {noBorder ? (
        <span className="text-green-info text-sm text-center group-hover:bg-pink-primary p-1">
          <span className="rounded-full bg-white p-1">
            KYC Verified
          </span>
        </span>
      ) : null}
    </Link>
  )
}

export default UserMenu
