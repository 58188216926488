import React from "react";
import RewardsRedeem from "./redeem/RewardsRedeem"
const Redeem = () => {
  return (
    <div className="w-full bg-gray-100 p-1 flex flex-col lg:flex-row gap-1 !bg-white">
      <RewardsRedeem/>
    </div>
  );
};

export default Redeem;
