import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import "../App.css";

const DropMenu = ({gone}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`${open === true ? "menu" : " "} relative dnoneLarge ${gone}`}
      tabIndex={0}
    >
      <button className="relative btn px-1 bg-white" onClick={handleClick}>
        <span className="border-b-gray-900 block mb-1 border-0 border-b-2 w-6"></span>
        <span className="border-b-gray-900 block mb-1 border-0 border-b-2 w-6 "></span>
        <span className="border-b-gray-900 block mb-1 border-0 border-b-2 w-6"></span>
      </button>

      {/* menu items */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          li: {
            minHeight: "2.1rem",
            width: "50vw",
            minWidth:"fit-content",
            paddingBlock: "0",
          },
          ul: {
            paddingBlock: "0",
          },
        }}
      >
        <Link to="/home/referandearn" data-route="/referandearn">
          <MenuItem onClick={handleClose}>Refer & Earn</MenuItem>
        </Link>
        <Link to="/home/rewards" data-route="/rewards">
          <MenuItem onClick={handleClose}>Rewards</MenuItem>
        </Link>

        <Link to="/home/offers" data-route="/offers">
          <MenuItem onClick={handleClose}>Offers</MenuItem>
        </Link>

        <Link to="/home/suggestions" data-route="/suggestions">
          <MenuItem onClick={handleClose}>Complain</MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

export default DropMenu;
