import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    return { hasError: true };
  }
  render() {
    if (!this.state.hasError) {
      return this.props.children;
    } else {
      return (
        <div className="w-full h-full bg-red-error text-white text-center">
          Some Error happeded
        </div>
      );
    }
  }
}

export default ErrorBoundary;
