import Button from "../button";
import {useLoginModal} from "./useLoginModal";
const LoginToAccess = ()=>{
    const {actionFuncion} = useLoginModal();
    return (
        <div className="w-full h-full flex flex-col items-center">
	  <span>
	    Please login to access this section 
          </span> 
	  <Button
            exClasses="px-4 tracking-wider"
            text="Login"
            click={()=>actionFuncion()}
          />
        </div>
    );
};

export default LoginToAccess;
