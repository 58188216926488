import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const loginId = useSelector((state) => state.loginManager.userId);
  const loginCredentials = useSelector(
    (state) => state.loginManager.jwtAndAuth
  );

  console.log(loginId, "li");
  console.log(loginCredentials, "lc");
  if (loginId === "" || Object.keys(loginCredentials).length === 0) {
    return <Navigate to="/" />;
  } else return children;
};

export default ProtectedRoute;
