import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { useTimer } from "use-timer";
import Button from "../button";
import { Input } from "../input";
import axios from "axios";
import { BASE_ROUTE } from "../routes";
import { isValidEmail } from "../usefullFunctions";
import { useRequestWithAuth } from "../customHooks/useRequestWithAuth";
import { useAutofill } from "../customHooks/useAutofill"
import { getClientIpAddress } from "../../Auth/AuthFunctions";
import { useLoginModal } from "./useLoginModal";
import WithTextInput from "../withTextInput"
import { useUserInfoRequest } from "../../Auth/UserRequests";
import PaddedModal from "../modals/PaddedModal";
import { paddedModalSizeConstatns } from "../constants";

export const Show = ({ when = false, children }) => {
  return when ? children : null
}

const ReVerifyEmail = ({
  closeModal = (some) => { },
  open = false,
  callback = () => { },
}) => {
  const { askUserInfo } = useUserInfoRequest()
  const [otpStatus, setOtpStatus] = useState("unsent");
  const { doActualPostRequest, doActualPutRequest } = useRequestWithAuth();
  const formRef = useRef("");
  const [showChangeEmail, setShowChangeEmail] = useState(false)
  const timer = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
  });
  const { info } = useLoginModal()
  const { autoFillotp } = useAutofill()
  useEffect(() => {
    formRef.current.setFieldValue("otpChangeEmail", autoFillotp, true)
  }, [autoFillotp])

  useEffect(() => {
    askUserInfo()
    setTimeout(() => {
      formRef.current.setFieldValue("currentEmail", sessionStorage.getItem("reverifyEmail"), true)
    }, 700)
  }, [])

  //stuff related to validation
  const initialValues = {
    currentEmail: "",
    newEmail: "",
    otpChangeEmail: "",
  };

  const matchEmailOtp = async (value, Email) => {
    if (value.length < 6) {
      return false;
    } else {
      return doActualPutRequest(
        "verifyotp",
        null,
        null,
        {
          servicename: "reverifyem",
          identifier: Email,
          mode: "web",
          otp: value,
          username: info.username
        },
        undefined,
        true
      )
        .then((res) => res)
        .catch((error) => error.response);
    }
  };

  const resetErrors = () => {
    formRef.current.setFieldError("otpChangeEmail", "");
    formRef.current.setFieldValue("otpChangeEmail", "", false);
    formRef.current.setFieldTouched("otpChangeEmail", false, false);
  }


  const runMatchOtp = async (otp, emailNo) => {
    matchEmailOtp(otp, emailNo).then((res) => {
      console.log(res?.data, "data")
      console.log(res.response, "response")
      if (res.response.status === 200) {
        setOtpStatus("verified");
        formRef.current.setFieldError("otpChangeEmail", undefined);
      } else {
        formRef.current.setFieldError("otpChangeEmail", "OTP does not match");
        formRef.current.setFieldTouched("otpChangeEmail", true, false);
      }
    });
  }

  const validate = (values) => {
    const errors = {};
    if (isValidEmail(values.currentEmail) !== "none") {
      errors.currentEmail = isValidEmail(values.currentEmail);
    }

    if (showChangeEmail) {
      if (isValidEmail(values.newEmail) !== "none") {
        errors.newEmail = isValidEmail(values.newEmail);
      }
      if (
        values.currentEmail === values.newEmail &&
        values.currentEmail !== ""
      ) {
        errors.newEmail = "Both new and old Email can't be same";
      }
      if (values.currentEmail !== info.email) {
        errors.currentEmail = "Current Email Number Incorrect";
      }
    }

    if (otpStatus === "sent" && values.otpChangeEmail.length === 6) {
      runMatchOtp(values.otpChangeEmail, showChangeEmail ? values.newEmail : values.currentEmail)
    }

    if (otpStatus !== "verified" || values.otpChangeEmail.length !== 6) {
      errors.otpChangeEmail = "OTP does not match";
    }
    return errors;
  };

  const reverifyEmail = async () => {
    doActualPostRequest("reverifystatus", null, null, {
      identifier: showChangeEmail ? formRef.current.values.newEmail : formRef.current.values.currentEmail,
      mode: "web",
      username: info.username,
      servicename: "reverifyem",
    }).then(() => {
      console.log("here")
      sessionStorage.setItem("reverifyEmail", "")
      closeModal()
      callback({name:"Email",status:true})
    }).catch(() => {
    })
  }

  const handleSubmit = (_) => {
      reverifyEmail().then(r => null)
  };

  //otp related section stsrts here

  const askOtp = async () => {
    let returnValue = "";

    returnValue = getClientIpAddress().then(async (ipClient) => {
      return axios
        .post(
          `${BASE_ROUTE}/getotp`,
          {
            servicename: "reverifyem",
            identifier: showChangeEmail ? formRef.current.values.newEmail : formRef.current.values.currentEmail,
            mode: "web",
            ipaddress: ipClient,
            username: info.username
          }
        )
        .then((resp) => resp)
        .catch((error) => error.response);
    })

    return returnValue;
  };

  const sendOtp = () => {
    askOtp().then((res) => {
      if (res.status !== 200) {
        formRef.current.setFieldTouched("currentEmail",true,true)
        formRef.current.setFieldError("currentEmail", res.data.Message);
      } else if (res.status === 200) {
        formRef.current.setFieldError("newEmail", "");
        formRef.current.setFieldError("currentEmail", "");
        setOtpStatus("sent");
        timer.reset();
        timer.start();
      }
    });
  };

  const otpBtnListener = (formik) => {
    if (otpStatus === "unsent") {
      sendOtp();
    } else if (otpStatus === "sent") {
      formik.setFieldValue("otpChangeEmail", "", false);
      formik.setFieldTouched("otpChangeEmail", false, false);
      formik.setFieldError("otpChangeEmail", "");
      setOtpStatus("unsent");
    } else if (otpStatus === "verified") {
      setOtpStatus("unsent");
      formik.setFieldValue("otpChangeEmail", "", false);
      formik.setFieldTouched("otpChangeEmail", false, false);
      formik.setFieldError("otpChangeEmail", "");
    }
  };

  const isAllowedToEnableChangeEmail = (oldEmail, newEmail) => {
    if (isValidEmail(oldEmail) === "none" && isValidEmail(newEmail) === "none") {
      return oldEmail !== newEmail && info.email === oldEmail;
    } else {
      return false;
    }
  }

  const isAllowedToEnableOtp = (oldEmail) => {
    // return false
    return isValidEmail(oldEmail) === "none" && showChangeEmail === false;
  };

  useEffect(() => {
    setOtpStatus("unsent")
    resetErrors()
  }, [showChangeEmail])


  useEffect(()=>{
    if(otpStatus === "verified"){
      formRef.current.handleSubmit()
    }
  },[otpStatus])

  //otp related secton ends here
  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(formik) => (
        <Form>
          <div>
            <PaddedModal closeModal={() => closeModal(false)} open={open} size={paddedModalSizeConstatns["2xl"]}>
              <div className="w-full h-full flex flex-col">
                <h2 className="font-semibold text-pink-primary text-lg text-center ">Re-verify Email</h2>
                <hr className="bg-pink-primary h-1 mb-4" />
                <div className="text-center font-bold text-xs text-blue-error">
                  {formik.touched.newEmail && formik.errors.newEmail ? formik.errors.newEmail :
                    formik.touched.currentEmail && formik.errors.currentEmail ? formik.errors.currentEmail :
                      formik.touched.otpChangeEmail && formik.errors.otpChangeEmail ? formik.errors.otpChangeEmail : null
                  }
                </div>
                <div className="grid grid-cols-12 gap-2 w-full items-center">
                  <span className="inline-block text-gray-primary col-span-3 text-xs lg:text-sm">
                    Email
                  </span>
                  {/* current Email number */}

                  <WithTextInput
                    iconClasses="text-xxs font-semibold"
                    disable={true}
                    numbersOnly={true}
                    type="email"
                    Id="currentEmail"
                    name="currentEmail"
                    val={formik.values.currentEmail}
                    change={(value) =>
                      formik.setFieldValue("currentEmail", value, true)}
                    blur={formik.handleBlur}
                    exClasses="text-gray-primary "
                    innerClasses="h-[28px] !text-sm"
                    fClasses="col-span-7"
                    text=""
                  />

                  <Button
                    exClasses="w-[3rem] col-span-1 h-[30px]"
                    text="OTP"
                    dis={
                      !isAllowedToEnableOtp(formik.values.currentEmail)
                    }
                    click={() => sendOtp(formik)}
                  />
                  <a className="text-pink-primary col-span-full text-end cursor-pointer text-xs mx-12 lg:mx-20 font-semibold whitespace-nowrap" onClick={() => setShowChangeEmail((prev) => !prev)}>Change Email</a>

                  <Show when={showChangeEmail}>
                    <span className="mt-2 text-gray-primary col-span-3 text-xs lg:text-sm mb-2 whitespace-nowrap">New Email</span>
                    {/* new Email number */}
                    <Input
                      extraClasses="col-span-7"
                      Id="newEmail"
                      maxlen={10}
                      dis={otpStatus === "sent" || otpStatus === "verified"}
                      iType="tel"
                      name="newEmail"
                      override={{ height: "28px", fontSize: "14px" }}
                      val={formik.values.newEmail}
                      change={(values) => formik.setFieldValue("newEmail", values, true)}
                      blurFunction={formik.handleBlur}
                    />
                    <Button
                      exClasses="w-[3rem] col-span-1 h-[28px]"
                      text={otpStatus !== "unsent" ? <i className="fa-regular fa-pen-to-square text-white w-5 "></i> : "OTP"}
                      dis={
                        !isAllowedToEnableChangeEmail(formik.values.currentEmail, formik.values.newEmail)
                      }
                      click={() => otpBtnListener(formik)}
                    />
                  </Show>

                  {/* otp section */}
                  {otpStatus === "unsent" || otpStatus === "verified" ? null : (
                    <>
                      {/*<div className="flex gap-2 w-28 items-center col-span-full">*/}
                      <span className="text-gray-primary col-span-3 text-xs lg:text-sm">OTP</span>
                      <Input
                        extraClasses="w-24 col-span-4"
                        Id="otpChangeEmail"
                        override={{ height: "28px", fontSize: "14px" }}
                        numbersOnly={true}
                        maxlen={6}
                        iType="tel"
                        name="otpChangeEmail"
                        val={formik.values.otpChangeEmail}
                        change={(values) =>
                          formik.setFieldValue("otpChangeEmail", values, true)
                        }
                      />
                      <span className="col-span-5 text-xxs lg:text-sm text-center">
                        {timer.time === 0 ? (
                          <Button
                            exClasses="w-[6rem] col-span-1 h-[28px] text-xxs whitespace-nowrap"
                            click={() =>{
                              formik.setFieldValue("otpChangeEmail","",false);
                              sendOtp()
                            }}
                            text="Resend OTP"
                          />
                        ) : (
                          `${Math.floor(timer.time / 60)}:${timer.time % 60}`
                        )}
                      </span>
                      {/*</div>*/}
                    </>
                  )}
                  {/*
                  <Button
                    exClasses="col-span-full"
                    text="Submit"
                    type="submit"
                    click={formik.handleSubmit}
                  />
                   */}
                </div>
              </div>
            </PaddedModal>
          </div>
        </Form >
      )}
    </Formik >
  );
};

export default ReVerifyEmail;
