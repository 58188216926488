import { useEffect } from "react";
import SimpleModal from "./simpleModal";
import Button from "../button";
import { FcApproval } from "react-icons/fc";
const FailureTemplateModal = ({
  message = "",
  status = false,
  title = "",
  open = false,
  setOpen = () => console.log(),
  exClasses = {},
  runOnEnd = () => console.log(),
}) => {
  useEffect(() => {
    return () => runOnEnd();
  }, []);
  if (open === false) return null;
  return (
    <SimpleModal
      size={false}
      open={open}
      height={false}
      closeOnBackgroudClick={false}
      closeModal={setOpen}
      exClasses="px-1 py-1 md:h-[12rem] top-1/4 bottom-1/2"
      exOverride={exClasses}
    >
      <div className="h-full w-full flex flex-col justify-center items-center px-2">
          <p
            className={`text-sm font-bold  ${ status == false ? "text-red-error" : "text-green-info" } pt-2 capitalize`}
          >
            {message.includes("verify")?"Verification error":title}
          </p>
        <hr className="bg-red-error  w-full h-1"/>
        <div className="text-center text-xl  p-0 pb-1 w-full flex items-center mt-2 justify-center">
          {status ? (
            <FcApproval size="2rem" />
          ) : (
            <img alt="alt" src="/error.png" className="w-8 h-8 mb-2" />
          )}
          <p className="text-xs px-2">{message}</p>
        </div>
        <div className="m-1">
          <Button text="OK" click={() => setOpen()} exClasses="px-4 py-1 h-[28px]" />
        </div>
      </div>
    </SimpleModal>
  );
};

export default FailureTemplateModal;
