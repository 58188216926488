/* This file is used in diffrent service files for geving arguments
 *  to there respective cards which the display as confirmation
 *  DON'T CONFUSE THIS WITH CONFIRM PAGE CONVERTOR WHICH  ARE USED FOR
 *  DISPLAYING FINAL RECEIPTHS FOR DIFFERENT TRANSACTIONS
 */

import prepaidConfirm from "../subServices/specialJsons/prepaidConfirm.json"
import postpaidConfirm from "../subServices/specialJsons/postpaidConfirm.json"
import dthConfirm from "../subServices/specialJsons/dthConfirm.json"
import electricityConfirm from "../subServices/specialJsons/ElectricityConfirm.json"
import broadbandConfirm from "../subServices/specialJsons/broadbandConfirm.json"
import waterConfirm from "../subServices/specialJsons/waterConfirm.json"
import fastagConfirm from "../subServices/specialJsons/fastagConfirm.json"
import giftcardConfirm from "../subServices/specialJsons/giftCardConfirm.json"
import insuranceConfirm from "../subServices/specialJsons/insuranceConfirm.json"
export const prepaidConvertor = (
  heading = "",
  mobileNumber = 0,
  operator = "some operator",
  circle = "circle",
  description = "desc",
  amount=0
) => {
  const newJson = { ...prepaidConfirm }
  newJson.heading = heading
  newJson.dataColumns[0].value = mobileNumber
  switch (operator) {
    case "AR":
      newJson.dataColumns[1].value = "Airtel"
      break
    case "BS":
      newJson.dataColumns[1].value = "BSNL"
      break
    case "MD":
      newJson.dataColumns[1].value = "MTNL_Delhi"
      break
    case "MM":
      newJson.dataColumns[1].value = "MTNL_Mumbai"
      break
    case "RJ":
      newJson.dataColumns[1].value = "Reliance JIO"
      break
    case "VF":
      newJson.dataColumns[1].value = "Vodafone"
      break
    default:
      console.log("wrong operator", operator)
  }
  if (circle === "" || circle === " ") {
    newJson.dataColumns[2].value = ""
  } else {
    newJson.dataColumns[2].value = JSON.parse(circle).name
  }
  newJson.dataColumns[3].value = description
  newJson.dataColumns[4].value = amount
  return { ...newJson }
}

export const dthConverter = (
  heading = "",
  dthNumber = "",
  operator = "",
  amount = ""
) => {
  const newJson = { ...dthConfirm }
  newJson.heading = heading
  newJson.dataColumns[0].value = dthNumber
  newJson.dataColumns[1].value = operator
  newJson.dataColumns[2].value = amount

  return { ...newJson }
}

export const postpaidConvertor = (
  consumerNo = "",
  consumerName = "",
  billAmount = "0",
  dueDate = Date()
) => {
  const newJson = { ...postpaidConfirm }
  newJson.heading = "Postpaid Bill Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  newJson.dataColumns[3].value = billAmount

  return { ...newJson }
}

export const electricityConvertor = (
  consumerNo = "",
  consumerName = "",
  billAmount = "0",
  dueDate = Date()
) => {
  const newJson = { ...electricityConfirm }
  newJson.heading = "Electricity Bill Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  newJson.dataColumns[3].value = billAmount

  return { ...newJson }
}

export const gasConvertor = (
  consumerNo = "",
  consumerName = "",
  billAmount = "0",
  dueDate = Date()
) => {
  const newJson = { ...electricityConfirm }
  newJson.heading = "Gas Bill Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  newJson.dataColumns[3].value = billAmount

  return { ...newJson }
}

export const broadbandConvertor = (
  consumerNo = "",
  consumerName = "",
  billAmount = "0",
  dueDate = Date()
) => {
  const newJson = { ...broadbandConfirm }
  newJson.heading = "Broadband & Landline Bill Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  newJson.dataColumns[3].value = billAmount

  return { ...newJson }
}

export const waterConvertor = (
  consumerNo = "",
  consumerName = "",
  billAmount = "0",
  dueDate = Date()
) => {
  const newJson = { ...waterConfirm }
  newJson.heading = "Water Bill Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  newJson.dataColumns[3].value = billAmount

  return { ...newJson }
}

export const fastagConvertor = (
  customerName = "",
  vechileNo = ""
) => {
  const newJson = { ...fastagConfirm }
  newJson.dataColumns[0].value = customerName
  newJson.dataColumns[1].value = `${vechileNo}`
  return { ...newJson }
}

export const giftCardConverter = (
  operator = "NA",
  amount = 0
) => {
  const newJson = { ...giftcardConfirm }
  newJson.heading = "Giftcard Details"
  newJson.dataColumns[0].value = `${operator}`
  newJson.dataColumns[1].value = `${amount}`
  return { ...newJson }
}

export const insuranceConverter = (
  consumerNo="",
  billAmount = "0",
  consumerName = "",
  dueDate = Date(),
  liveCharges = 0
) => {
  const newJson = { ...insuranceConfirm }
  newJson.heading = "Insurance Details"
  newJson.dataColumns[0].value = consumerNo
  newJson.dataColumns[1].value = consumerName
  newJson.dataColumns[2].value = dueDate
  try{
   newJson.dataColumns[3].value = billAmount !== "NA"?parseFloat(billAmount):"NA"
  }catch(e){
   newJson.dataColumns[3].value = 0
  }
  newJson.dataColumns[4].value = liveCharges

  return { ...newJson }
}
