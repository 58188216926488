import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import Table from "./Table";
import Button from "./button";
import { NumberInput } from "./numberInput";
import { useSelector } from "react-redux";
import DonationHistory from "./modals/DonationHistory"
import { useLoginModal } from "./userpages/useLoginModal"
import { useRequestWithAuth } from "./customHooks/useRequestWithAuth"
import SmallModal from "./userpages/smallModal"
import "../css/earnPoints.css";
import { useUserInfoRequest } from "../Auth/UserRequests"
import {getClientIpAddress} from "../Auth/AuthFunctions"

const EarnPoints = () => {
  const [donation, setDonation] = useState("");
  const [showStatus, setShowStatus] = useState(false)
  const userInfo = useSelector((state) => state?.userInfo?.userInfo);
  const [donationHistoryModal, setShowDonationHistoryModal] = useState(false)
  const { doActualPostRequest } = useRequestWithAuth()
  const { info, loginState } = useLoginModal()
  const { askUserInfo } = useUserInfoRequest()

  let items = [
    { heading: "Recharge Mobile/DTH", content: "Earn 2% Points" },
    { heading: "Refer A Friend ", content: "Earn 05 Points" }
  ];

  useEffect(() => {
    if (loginState) {
      askUserInfo()
    }
  }, [loginState])

  const initialValues = {
    donationAmount: "",
  };

  const validate = (values) => {
    const errors = {};
    if (values.donationAmount === "") {
      errors.donationAmount = "Donation amount can't be empty";
    }
    if (values.donationAmount === 0) {
      errors.donationAmount = "Donation amount can't be 0";
    }
    if (values.donationAmount > 25000) {
      errors.donationAmount = "Donation amount can't be more then 25000";
    }
    if (values?.donationAmount > info?.walletBalance) {
      errors.donationAmount = "you don't have wallet sufficient balance";
    }

    return { ...errors };
  };

  const handleSubmit = async (values) => {
    const ipClient = await getClientIpAddress()
    doActualPostRequest("user/donatebill", null, null, {
      username: info.username,
      amount: values.donationAmount,
      servicename: "donatepay",
      mode: "web",
      ipaddress:ipClient
    }).then(res => {
      if (res.status === 200) {
        setShowStatus(true)
        setDonation(res.data)
      }
    })
  };

  return (
    <section>
      <Table headings={["Activity", "Earn Rewards Points"]} items={items} />

      <div className="flex items-center justify-right bg-pink-400 h-[40px] px-[15px] w-order box-border mb-3 mt-4 hidden">
        <h1 className="text-white text-[16px] md:text-[18px] py-[10px] px-1 capitalize">
          donate to social care fund
        </h1>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formik) => (
          <Form className="flex  flex-col md:flex-row gap-1 md:mt-6 hidden">
            <div className="w-full md:w-56 h-fit">
              <span className="ml-1 leading-6 font-normal text-sm flex-1 text-pink-primary">
                Wallet Balance Rs &nbsp;
                {userInfo.walletBalance}
              </span>
              <NumberInput
                iType="tel"
                Id="donationAmount"
                name="donationAmount"
                change={(values) =>
                  formik.setFieldValue("donationAmount", values, true)
                }
                blur={formik.handleBlur}
                val={formik.values.donationAmount}
                onleft="Rs. "
                numbersOnly={true}
                maxlen={5}
                extraClasses=" text-[15px]"
                fieldClasses="border-pink-600 focus:outline-none flex-1"
              />
              <span className="text-xs text-pink-primary">(max Rs 25000 donation)</span>
              <div className="inline-block text-blue-error text-xs">
                <ErrorMessage name="donationAmount" />
              </div>
            </div>

            <button
              className="bg-pink-primary h-[36px] px-2 w-full md:w-40 rounded mr-auto md:mt-6 md:max-w-fit"
              type="submit">
              <span className="text-white font-normal">
                Donate Rs{" "}
                {formik.values.donationAmount > 25000
                  ? 25000
                  : formik.values.donationAmount}
              </span>
            </button>
            {loginState ? (<Button
              text="Donate History"
              exClasses="mt-6 ml-auto w-full md:w-40 md:mr-auto lg:mr-[40%] "
              override={{ fontSize: "16px", fontWeight: "400" }}
              click={() => setShowDonationHistoryModal(true)} />) : null}
            {loginState ? (
              <DonationHistory open={donationHistoryModal}
                closeModal={() => setShowDonationHistoryModal(false)} />
            ) : null}
            <SmallModal open={showStatus} closeModal={() => setShowStatus(false)}>
              <div className="flex flex-col gap-2">
                {donation?.Status === "Success" ? (
                  <img src="/images/tick.png" style={{ width: "4rem", height: "4rem", marginInline: "auto" }} />
                ) : (
                  <img src="/error.png" style={{ width: "4rem", height: "4rem", marginInline: "auto" }} />
                )}
                <span className="text-xs">
                  {donation?.Message}
                </span>
              </div>
            </SmallModal>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EarnPoints;
