import {useContext} from "react";
import {LogoutContext} from "../context/LogoutContext"
import {useLocation,useNavigate} from "react-router-dom"
import {useSelector} from "react-redux";

export const useLogout = ()=>{
	const userId = useSelector((state) => state.loginManager.userId);
	const location = useLocation();
	const navigate = useNavigate();
	const logoutUser = useContext(LogoutContext);

	const logUserOut = () => {
		if(location.pathname.includes("/home/prepaid")){
			logoutUser.logout(userId);
		}else{
			navigate("/home/prepaid",{state:{ logout:true, userId } });
      }
  };

  return {logUserOut}
}
