import React,{forwardRef} from "react";

const WithTextInput = forwardRef(function WithTextInput ({
  text = "some text",
  textClick = () => console.log("text clicked"),
  placeholder = "",
  Id = "",
  change = (e) => console.log(e.target.value),
  disable = false,
  blur = (e) => console.log(e.target.value),
  val,
  alphanumeric = false,
  type = "text",
  name = "",
  numbersOnly = false,
  maxlen = -1,
  req = false,
  exClasses = " ",
  innerClasses="",
  iconClasses="",
  iconsOverride={},
  fullCaps=false,
  fClasses=""
}, ref){
  console.log("this is placeholder", placeholder);
  const handleChange = (e) => {
    const returnValue = fullCaps?e.target.value.toUpperCase():e.target.value
    console.log(returnValue);
    let len = returnValue?.length??0;
    //check if alphanumeric is allowed
    if (alphanumeric) {
      if (maxlen != -1) {
        if (maxlen > 0 && len <= maxlen) {
          change(returnValue.replace(/[^A-Za-z0-9]/g, ""));
          return;
        }
      } else {
        change(returnValue.replace(/[^0-9A-Za-z]/g, ""));
        return;
      }
    }

    //check if number only is allowed
    if (numbersOnly) {
      if (maxlen != -1) {
        if (maxlen > 0 && len <= maxlen) {
          change(
            returnValue.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
            );
          return;
        }
      } else {
        change(
          returnValue.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
          );
        return;
      }
    } else {
      if (maxlen <= 0) {
        change(returnValue);
        return;
      } else if (maxlen > 0 && len <= maxlen) {
        console.log(returnValue);
        change(returnValue);
        return;
      }
    }
  };
  return (
    <div className={`rounded w-full ${fClasses}`}>
      <span
        className={`flex border border-pink-600  m-0 w-full relative rounded pb-px ${exClasses}`}
        tabIndex={0}
        >
        <input
          type={type}
          className={`border-0 w-full  m-0 outline-none p-[11px] rounded text-[13px] leading-[21px] h-[34px] text-gray-primary disabled:bg-gray-200 text-base focus:text-pink-primary ${innerClasses}`}
          name={name}
          disabled={disable}
          id={Id}
          required={req}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={blur}
          value={val}
          ref={ref}
        />

        {/* this <span> will be visible inside the input box so be careful before editing it */}
        <span
          className={`absolute underline capitalize right-1 mt-2 text-xs cursor-pointer text-pink-primary hover:text-black hover:no-underline ${iconClasses}`}
          onClick={() => textClick()}
          style={{...iconsOverride}}
          >
          {text}
        </span>
      </span>
    </div>
    );
});

export default WithTextInput;
