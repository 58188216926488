import { useState, useEffect } from "react"
import { Tabs, useTabState, Panel } from "@bumaga/tabs"
import RedeemDescription from "./RedeemDescription"
import { useRequestWithAuth } from "../customHooks/useRequestWithAuth"
import {useLoginModal} from "../userpages/useLoginModal"
import { IMAGE_ROUTE } from "../routes"
import ConvertToCash from "./ConvertToCash"

const cn = (...args) => args.filter(Boolean).join(" ")

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState()

  return (
    <button
      className={cn(
        "text-[10px] lg:text-sm lg:w-full p-2 bg-gray-200 text-start lg:font-thin text-gray-600",
        isActive && "!bg-pink-primary !text-white !font-semibold"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const GiftCardItem = ({ src = "", desc = "", value = "", points = "", click = () => { },rounded=false }) => {
  return (
    <section className="" onClick={() => click({ src, desc, value, points })}>
      <div className="relative w-64 lg:w-full mx-auto">
        <img src={src} className="w-full h-full" />
        <span className={`absolute bottom-0 right-0 bg-green-400 text-white font-bold px-2 py-1 ${rounded?"rounded-br-md":""}`}>
          BUY
        </span>
      </div>
      <p className="text-center text-pink-primary font-semibold">{points} Points</p>
    </section>
  )
}

const RedeemRedeem = () => {
  const [state, setState] = useState(0)
  const [show, setShow] = useState(null)
  const [data, setData] = useState({})
  const { doActualGetRequest } = useRequestWithAuth()
  const {info,loginState} = useLoginModal()
  const setItemRedeemItem = (value) => {
    console.log("called", value)
    setShow(value)
  }

  const handleReset = () => {
    if (show !== null) {
      setShow(null)
    }
  }
  useEffect(() => {
    setState(0)
    doActualGetRequest("skuproduct").then((res) => {
      setData(res?.data ?? {})
    })
  }, [])

  useEffect(() => {
    setShow(null)
  }, [state])

  return (
    <Tabs state={[state, setState]}>
      <section
        className="flex flex-col h-full w-full lg:max-w-fit"
        onClick={handleReset}
      >
        <div className="flex flex-col  h-fit lg:h-20 bg-pink-primary text-white items-center px-2 py-2 mb-1">
          <span className="font-medium">Reward Points</span>
          <span className="text-xl font-semibold">{loginState?info.rewardPoint:"NA"}</span>
        </div>
        <div className="flex flex-row flex-wrap justify-center lg:flex-col w-full justify-none  ">
          <Tab>RechargeAXN e-Gift Card</Tab>
  {/*           <Tab>Google Play e-card</Tab>*/}
          <Tab>Convert to Cash</Tab>
          <Tab>Accessories</Tab>
          <Tab>Electronics</Tab>
        </div>
      </section>
      <Panel>
        <section className="">
          <div className="col-span-full p-1 flex flex-col">
            <span className="mx-auto text-sm  max-w-fit lg:max-w-full lg:text-md font-semibold hidden md:block">
              e-Gift Vouchers gets delivered instantly on your Mobile
              number & E-mail id.
            </span>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 mlg:grid-cols-3 gap-6 h-64 lg:h-[28rem] overflow-y-auto">
            {show !== null ? (
              <RedeemDescription src={show.src} desc={show.desc} value={show.value} points={show.points} />
            ) : (
              <>
                {data?.RechargeAXN?.map((ele) => (
                  <GiftCardItem
                    src={`${IMAGE_ROUTE}/sku/${ele.skucode}.png`}
                    click={(value) => setItemRedeemItem(value)}
                    desc={ele?.skucode}
                    value={ele?.value ?? 0}
                    points={ele?.points ?? 0}
                  />
                ))}
              </>
            )}
          </div>
        </section>
      </Panel>
 {
//       <Panel>
//         <section className="">
//           <div className="col-span-full p-1 hidden lg:flex lg:flex-col ">
//             <span className="mx-auto text-sm max-w-fit lg: max-w-full lg:text-md font-semibold hidden md:block">
//               e-Gift Vouchers gets delivered instantly on your Mobile
//               number & E-mail id.
//             </span>
//           </div>
//           <div className="grid grid-cols-1 lg:grid-cols-2 mlg:grid-cols-3 gap-2 lg:gap-4 mlg:gap-6 h-64 lg:h-[28rem] overflow-y-auto">
//             {show !== null ? (
//               <RedeemDescription src={show.src} desc={show.desc} value={show.value} points={show.points} />
//             ) : (
//               <>
//                 {data?.GooglePlay?.map((ele) => (
//                   <GiftCardItem
//                     src={`${IMAGE_ROUTE}/sku/${ele.skucode}.png`}
//                     click={(value) => setItemRedeemItem(value)}
//                     desc={ele?.skucode}
//                     value={ele?.value ?? 0}
//                     points={ele?.points ?? 0}
//                     rounded={true}
//                   />
//                 ))}
//               </>
//             )}
//           </div>
//         </section>
//       </Panel>
}
      <Panel>
        {data?.Cash?.map((ele) => (
          <ConvertToCash
            src={`${IMAGE_ROUTE}/sku/${ele.skucode}.png`}
            points={ele?.points}
            value={ele?.value}
            desc={ele?.skucode}
          />
        ))}
      </Panel>
      <Panel>
      <div className="flex items-center justify-center w-full font-bold">
        <span className="text-green-info">
          coming soon..
        </span>
      </div>
      </Panel>
      <Panel>
       <div className="flex items-center justify-center w-full font-bold">
        <span className="text-green-info">
          coming soon..
        </span>
       </div>
      </Panel>
    </Tabs>
  )
}

export default RedeemRedeem
