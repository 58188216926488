import {useParams,Navigate} from "react-router-dom";
import {BASE_ROUTE} from "../routes";
import axios from "axios";
import {useEffectOnce} from "react-use";
import {useState} from "react";

const PromoRedirect =  () => {
    const params = useParams();
    const [promoState,setPromoState] = useState(null);
                 
    useEffectOnce(()=>{
        axios
            .get(`${BASE_ROUTE}/checkrefer/${params?.r}`)
            .then((response)=>{
                console.log("this is data",response.data);
                setPromoState(true);})
            .catch((error)=>{
                console.log("this is error",error.response);
                setPromoState(false);});
    });

    //r is the referer id 
    if(promoState === null){
        return null; 
    }

    if(promoState){
        return (
        <Navigate to="/home/signupreferred" state={{REFER_CODE:params?.r}}/> 
        );
    }else {
        return   <Navigate to="/home/signupreferred" state={{REFER_CODE:undefined}}/> ;
    }
};

export default PromoRedirect;
