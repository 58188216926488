import Button from "../button"
import { Input } from "../input"
import { Form, Formik, ErrorMessage } from "formik"
import {useState} from "react"
import {useRequestWithAuth} from "../customHooks/useRequestWithAuth"
import { useLoginModal } from "../userpages/useLoginModal"
import Modal from "../modals/SmallActionResultModal"

const ConvertToCash = ({ src = "", desc = "", value = "", points = "" }) => {
	const { info,loginState,actionFuncion } = useLoginModal()
  const {doActualPostRequest} = useRequestWithAuth()
  const [show,setShow] = useState(false)
  const [message,setMessage] = useState({
    Status:"Failure",
    Message:""
  })

	const validate = (values) => {
		const error = {}
		if (info?.rewardPoint < values.rCount) {
			error.rCount = "Not Enough Reward Points"
		}
		if (values.rCount === 0) {
			error.rCount = "Not Enough Reward Points"
		}
		if(values.rCount % 5 !== 0){
			error.rCount = "Points Should be in multiple of 5"
		}
		if (values.rCount === "") {
			error.rCount = "Empty Reward Points not allowed"
		}
		return { ...error }
	}

	const handleSubmit = (values) => {
			if(!loginState){
				actionFuncion()
				return
		  }

		  doActualPostRequest("user/convertcash",{
					skucode:desc,
					username:info?.username,
				  points:values.rCount,
					mode:"web"
		}).then((res)=>{
				setMessage(res?.data)
				setShow(true)
		})
	}


	const getConversion=(val)=>{
		if(val === ""){
				return 0
		}
		if(val === 0){
				return 0
		}
		return ((value / points).toFixed(2) * val).toFixed(2)
}

	return (
		<section className="h-fit grid grid-cols-1 lg:grid-cols-2 gap-4">
			<h3 className="text-center col-span-full">Instant conversion of reward points to cash (under my wallet)</h3>
			<img className="h-64 w-32 h-32 mx-auto" src={src}/>
			<div className="flex flex-col items-center font-bold gap-4 mx-auto lg:w-fit">
				<h4 className="p-2 bg-pink-primary text-white w-full text-center">Conversion Rate</h4>
				<h6 className="text-pink-primary w-full text-center text-xs">(Minimum 5 Reward Points to Convert)</h6>
				<p className="text-pink-primary ">{value} Reward Point =  Rs {(value / points).toFixed(2)}</p>
				<Formik onSubmit={handleSubmit} validate={validate} initialValues={{ rCount: 0 }}>
					{(formik) => (
						<Form className="flex flex-col gap-4 justify-center">
							<div className="flex gap-2 items-center">
								<label className="font-medium whitespace-nowrap">Reward Point</label>
								<Input
									Id="rCount"
									name="rCount"
									val={formik.values.rCount}
									change={(val) => formik.setFieldValue("rCount", val, true)}
									blurFunction={formik.handleBlur}
									numbersOnly={true}
									extraClasses="w-28 mx-auto"
									iType="tel"
									/>
								<span className="whitespace-nowrap"> {"="} Rs {getConversion(formik.values.rCount)}</span>
							</div>
							<div className="text-xs font-thin text-blue-error text-center">
								<ErrorMessage name="rCount" />
							</div>
							<Button text="Convert to Cash" exClasses="w-full" type="submit"/>
						</Form>
					)}
				</Formik>
			</div>
      <Modal message={message.Message}
				title="Cash Convert Status"
				status={message.Status === "Success"}
				open={show}
				setOpen={()=>setShow(false)}>
      {message}
      </Modal>
		</section>
	)
}
export default ConvertToCash
