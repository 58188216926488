import React from "react";
// import "./index.css"
import { Navigate, Route, Routes } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import Home from "./components/home";

import Offers from "./components/Offers";
import Rewards from "./components/Rewards";
import Suggestions from "./components/Suggestions";
import WrapperPrepaid from "./components/wrapperPrepaid";
import WrapperPostpaid from "./components/wrapperPostpaid";
import ProtectedRoutes from "./Auth/ProtectedRoute";
//lazy loaded
//test
const TestPrepaidConfirm = React.lazy(() =>
  import("./components/test/TestPrepaidConfirm")
);

const LazyContactUs = React.lazy(() => import("./components/ContactUs"));
const LazyPromoSignUp = React.lazy(() =>
  import("./components/userpages/ReferredSignUpWrapper")
);
const LazyAboutUs = React.lazy(() => import("./components/AboutUs"));
const LazyFaqSection = React.lazy(() => import("./components/Faq"));
const LazyTerms = React.lazy(() => import("./components/terms"));
const LazyPrivacy = React.lazy(() => import("./components/Privacy"));
const LazyIntermediatePage = React.lazy(() =>
  import("./components/userpages/intermediatePage")
);
const LazyRefund = React.lazy(() => import("./components/Refund"));
const LazyWalletPage = React.lazy(() =>
  import("./components/userpages/walletPage")
);
const LazyUserProfile = React.lazy(() =>
  import("./components/userpages/userProfile")
);
const LazyConfirmPage = React.lazy(() => import("./components/confirmPage"));
//
const LazyDth = React.lazy(() => import("./components/subServices/dth"));
const LazyElectricity = React.lazy(() =>
  import("./components/subServices/Electricity")
);
const LazyGasLpg = React.lazy(() => import("./components/subServices/gasLpg"));
const LazyGiftCard = React.lazy(() =>
  import("./components/subServices/giftCard")
);

const LazyWater = React.lazy(() => import("./components/subServices/water"));
const LazyBroadbandLandline = React.lazy(() =>
  import("./components/subServices/broadbandLandline")
);
const LazyInsurance = React.lazy(() =>
  import("./components/subServices/insurance")
);
const LazyFastag = React.lazy(() => import("./components/subServices/fastag"));
const LazyReferAndEarn = React.lazy(() =>
  import("./components/userpages/ReferAndEarn")
);
const LazyRedeemGiftCard = React.lazy(() =>
  import("./components/redeem/RedeemEGiftCard")
);
const LazyAadhar = React.lazy(() =>
  import("./components/userpages/aadhar_verification/aadhar_verify")
);
const LazyBlockedAmount = React.lazy(() =>
  import("./components/userpages/blocked_amount/BlockedAmount")
);
const LazyAddress = React.lazy(() =>
  import("./components/userpages/Address/AddressVerification")
);
const LazyPaymentPage = React.lazy(() =>
  import("./components/PaymentGateway/GatewayPage")
);
const LazyAddMoney = React.lazy(() =>
  import("./components/userpages/wallet/addMoneyToWallet")
);
const LazyTransferMoney = React.lazy(() =>
  import("./components/userpages/wallet/TransferMoney")
);
const LazyCashbackInfo = React.lazy(() =>
  import("./components/userpages/wallet/cashbackInfo")
);

const LazyPersonalInfo = React.lazy(() =>
  import("./components/userpages/profile/personalInfo")
);

const LazyChangePassword = React.lazy(() =>
  import("./components/userpages/profile/changePassword")
);

const LazyTransactionHistory = React.lazy(() =>
  import("./components/userpages/profile/TransactionHistory")
);
const LazyKyc = React.lazy(()=>import("./components/userpages/profile/Kyc"))
const LazyTemporaryAddMoney = React.lazy(()=>import("./components/userpages/wallet/temp/temproraryAddMoneyToWallet"))
const LazyTempGateway = React.lazy(()=>import("./components/userpages/wallet/temp/tempGateway"))
const LazyDeleteAccount = React.lazy(()=>import("./components/DeleteAccount/deleteAccountPage"))
const LazyLocationTesting = React.lazy(()=>import("./components/LocationTesting/LocationTesting"))


let persistor = persistStore(store);
const FrontRoot = () => {
  return (
    <Provider store={store}>
      {/* <Router> */}
      <React.Suspense fallback="loading...">
        <Routes>
          <Route
            path="/*"
            element={
              <PersistGate persistor={persistor}>
                <App />
              </PersistGate>
            }
          >
            {/* <Route index element={<Navigate replace to="/home/prepaid" />} /> */}

            <Route element={<Home />}>
              <Route
                index
                element={<Navigate replace to="/home/prepaid" />}
              />
              <Route path="prepaid" element={<WrapperPrepaid />} />
              <Route path="postpaid" element={<WrapperPostpaid />} />
              <Route path="recharge_bill_dth" element={<LazyDth />} />
              <Route
                path="recharge_bill_electricity"
                element={<LazyElectricity />}
              />
              <Route path="recharge_bill_gas" element={<LazyGasLpg />} />
              <Route path="giftcard" element={<LazyGiftCard />} />
              <Route path="water" element={<LazyWater />} />
              <Route
                path="broadbandlandline"
                element={<LazyBroadbandLandline />}
              />
              <Route path="insurance" element={<LazyInsurance />} />
              <Route path="fastag" element={<LazyFastag />} />
            </Route>
            <Route path="test">
              <Route
                path="prepaid/:type"
                element={<TestPrepaidConfirm />}
              />
            </Route>
            <Route path="aboutus" element={<LazyAboutUs />} />
            <Route path="signupreferred" element={<LazyPromoSignUp />} />
            <Route path="faq" element={<LazyFaqSection />} />
            <Route path="contactus" element={<LazyContactUs />} />
            <Route path="terms" element={<LazyTerms />} />
            <Route path="privacypolicy" element={<LazyPrivacy />} />
            <Route path="delete-account" element={<LazyDeleteAccount />} />
            <Route path="redirect" element={<LazyIntermediatePage />} />
            <Route path="payaidredirect" element={<LazyIntermediatePage />} />
            <Route path="getepayredirect" element={<LazyIntermediatePage />} />
            <Route path="location-testing" element={<LazyLocationTesting />} />

            <Route path="refundpolicy" element={<LazyRefund />} />
            <Route path="offers" element={<Offers />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="suggestions" element={<Suggestions />} />
            <Route
              path="aadhar"
              element={
                <ProtectedRoutes>
                  <LazyAadhar />
                </ProtectedRoutes>
              }
            />
            <Route
              path="redeemgift"
              element={
                <ProtectedRoutes>
                  <LazyRedeemGiftCard />
                </ProtectedRoutes>
              }
            />
            <Route
              path="confirm"
              element={
                <ProtectedRoutes>
                  <LazyConfirmPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="wallet"
              element={
                <ProtectedRoutes>
                  <LazyWalletPage />
                </ProtectedRoutes>
              }
            >
              <Route
                index
                element={<Navigate replace to="/home/wallet/addmoney" />}
              />
              {
              // <Route path="addmoney" element={<LazyAddMoney />} />
              }
             <Route path="addmoney" element={<LazyTemporaryAddMoney />} />
              <Route path="cashbackinfo" element={<LazyCashbackInfo />} />
              <Route path="transfermoney" element={<LazyTransferMoney />} />
              <Route path="addmoney/continue" element={<LazyPaymentPage />} />
            </Route>
            <Route
              path="kyc"
              element={
                <ProtectedRoutes>
                  <LazyKyc/>
                </ProtectedRoutes>
              }
            />
           <Route path="gateway-temp" element={
              <ProtectedRoutes>
                <LazyTempGateway/>
              </ProtectedRoutes>
            } />
            <Route
              path="profile"
              element={
                <ProtectedRoutes>
                  <LazyUserProfile />
                </ProtectedRoutes>
              }
            >
              <Route
                index
                element={<Navigate replace to="/home/profile/info" />}
              />
              <Route path="info" element={<LazyPersonalInfo />} />
              <Route path="changepass" element={<LazyChangePassword />} />
              <Route
                path="transaction-history"
                element={<LazyTransactionHistory />}
              />
            </Route>

            <Route
              path="blocked_amount"
              element={
                <ProtectedRoutes>
                  <LazyBlockedAmount />
                </ProtectedRoutes>
              }
            />
            <Route
              path="address"
              element={
                <ProtectedRoutes>
                  <LazyAddress />
                </ProtectedRoutes>
              }
            />
            <Route path="referandearn" element={<LazyReferAndEarn />} />
            <Route
              path="*"
              element={<Navigate replace to="/home/prepaid" />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </Provider>
  );
};

export default FrontRoot;
