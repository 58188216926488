import React, { useState } from "react";
// import { ModalContext } from "../App"
// import { useDispatch } from "react-redux"
import Toast from "../../admin/components/Utility/Toast";
import Button from "./button";
//loginmodal imported as DetailModal
import DetailModal from "./userpages/loginModal";
import Danger from "./danger";
import { months } from "./constants"
const Offercard = ({
  promocode,
  cashback,
  title,
  frequency,
  details,
  validTill,
  isDailyOrWeekly = "",
  rateOrDailyOrWeekly = ""
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [toast, showToast] = useState(false);
  const [messageAndStatus, setMessageAndStatus] = useState({
    message: "",
    status: false,
  });

  const handleClick = () => {
    setOpenModal(true);
  };
  const formatDate = (dateAsString) => {
    let data = new Date(dateAsString);
    let day = data.getDate();
    let month = data.getMonth() + 1;
    let year = data.getFullYear();

    month = months[data.getMonth()]
    return `${day}-${month}-${year}`;
  };

  const copyToClipboard = (e) => {
    console.log("attempting to copy");
    navigator.clipboard.writeText(e.target.innerText).then(
      () => {
        setMessageAndStatus({
          message: "Copied to clipboard",
          status: true,
        });
        showToast(true);
        console.log("copied");
      },
      () => {
        setMessageAndStatus({
          message: "Failed to copy",
          status: false,
        });
        showToast(true);
      }
    );
  };

  return (
    <div className="offerGrid gap-2 p-4 shadow-md border border-gray-200 md:hover:shadow-2xl transition-shadow duration-200">
      <strong
        className="col-span-full flex  self-center leading-5 relative -top-3"
        style={{ fontSize: "13px" }}
      >
        <span className="text-pink-primary text-md">{isDailyOrWeekly === "daily" ? "Daily Coupon" : (isDailyOrWeekly === "weekly" ? "Weekly Coupon" : null)}</span>
        <span className="text-green-info ml-auto text-md ">
          Valid Till: {formatDate(validTill)}
        </span>
      </strong>
      {/* row 1 */}
      <strong className="col-span-full text-black text-center p-1 self-center" >
        {title}
      </strong>
      {/* row 2 */}
      <h4 className="col-span-full text-center text-pink-primary font-semibold capitalize">
        {frequency}
      </h4>
      {/* row 3 */}
      <div className="col-span-full  flex justify-left items-center border-2 border-blue-400 font-semibold rounded px-2 relative">
        <div className="text-black font-semibold text-md">Cashback:</div>
        <strong className="text-blue-800 p-1 text-[13px] lg:font-semibold lg:text-base">{cashback}</strong>
        <small className="text-green-info absolute -top-6 right-0 roboto-sm" style={{ fontSize: "12px" }} >{rateOrDailyOrWeekly}</small>
      </div>
      {/* row 4*/}
      <div className="flex items-center gap-1 border-2 border-blue-400 rounded px-2">
        <span className="inline-block px-1 text-black font-semibold text-md">
          Promocode:
        </span>
        <strong
          className="text-blue-800 inline-block text-[13px] lg:font-semibold lg:text-base"
          tabIndex={1}
          onClick={copyToClipboard}
        >
          {promocode}
        </strong>
      </div>
      <Button
        text="View Details"
        override={{ fontSize: "13px" }}
        click={() => handleClick()}
      />
      <DetailModal closeModal={() => setOpenModal(false)} open={openModal}>
        <div className="text-2xl text-center p-4 font-medium text-pink-primary">
          Terms &amp; Conditions
        </div>
        <Danger content={details} exClasses="prose-bullets-black" />
      </DetailModal>
      {toast ? (
        <Toast
          message={messageAndStatus.message}
          status={messageAndStatus.status}
          setOpen={() => showToast(false)}
        />
      ) : null}
    </div>
  );
};

export default Offercard;
