import React from "react";
import ReactDOM from "react-dom";

const SimpleModal = ({
  closeModal,
  open,
  exClasses = " ",
  exOverride = {},
  size = true,
  height = true,
  closeOnBackgroudClick = true,
  children,
  parentClass=""
}) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0 bg-gray-900/70 z-base"
        onClick={closeOnBackgroudClick === true ? () => closeModal() : null}
      ></div>
      <div
        className={`p-6 fixed inset-0 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 grid w-[96%]
          ${size === true ? "md:w-[34rem]" : "md:w-[20rem]"}
          ${height === true ? "md:h-[28rem]" : "md:h-[20rem]"}
          bg-white z-base ${exClasses} rounded`}
        style={{ ...exOverride }}
      >
        <div className="flex items-end h-2 w-full absolute top-7 pointer-events-auto">
          <button className="flex absolute -top-5 right-2  p-1 z-base" onClick={() => closeModal()} >
            <i className={ "fa-solid fa-xmark text-gray-primary hover:text-pink-primary" } ></i>
          </button>
        </div>
        <div className={`w-full h-auto overflow-y-auto relative ${parentClass}`}>{children}</div>
      </div>
    </>,
    document.querySelector("#portal")
  );
};

export default SimpleModal;
