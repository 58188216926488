import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { useTimer } from "use-timer";
import Button from "../button";
import { Input } from "../input";
import axios from "axios";
import { BASE_ROUTE } from "../routes";
import { isValidMobileNo } from "../usefullFunctions";
import { useRequestWithAuth } from "../customHooks/useRequestWithAuth";
import { useAutofill } from "../customHooks/useAutofill"
import { getClientIpAddress } from "../../Auth/AuthFunctions";
import { useLoginModal } from "./useLoginModal";
import WithTextInput from "../withTextInput"
import { useUserInfoRequest } from "../../Auth/UserRequests";
import PaddedModal from "../modals/PaddedModal"
import { paddedModalSizeConstatns } from "../constants";

const Show = ({ when = false, children }) => {
  return when ? children : null
}

const ReVerifyMobile = ({
  closeModal = () => { },
  open = false,
  callback = () => { },
}) => {
  const { askUserInfo } = useUserInfoRequest()
  const [otpStatus, setOtpStatus] = useState("unsent");
  const { doActualPostRequest, doActualPutRequest } = useRequestWithAuth();
  const formRef = useRef("");
  const [showChangeMobile, setShowChangeMobile] = useState(false)
  const timer = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
  });
  const { info } = useLoginModal()
  const { autoFillotp } = useAutofill()
  useEffect(() => {
    formRef.current.setFieldValue("otpChangeMobile", autoFillotp, true)
  }, [autoFillotp])

  useEffect(() => {
    askUserInfo()
    setTimeout(() => {
      formRef.current.setFieldValue("currentMobile", sessionStorage.getItem("reverifyMobile"), true)
    }, 700)
  }, [])

  //stuff related to validation
  const initialValues = {
    currentMobile: "",
    newMobile: "",
    otpChangeMobile: "",
  };

  const matchPhoneOtp = async (value, Mobile) => {
    if (value.length < 6) {
      return false;
    } else {
      return doActualPutRequest(
        "verifyotp",
        null,
        null,
        {
          servicename: "reverifyem",
          identifier: Mobile,
          mode: "web",
          otp: value,
          username: info.username
        },
        undefined,
        true
      )
        .then((res) => res)
        .catch((error) => error.response);
    }
  };

  const resetErrors = () => {
    formRef.current.setFieldError("otpChangeMobile", "");
    formRef.current.setFieldValue("otpChangeMobile", "", false);
    formRef.current.setFieldTouched("otpChangeMobile", false, false);
  }


  const runMatchOtp = async (otp, mobileNo) => {
    matchPhoneOtp(otp, mobileNo).then((res) => {
      console.log(res?.data, "data")
      console.log(res.response, "response")
      if (res.response.status === 200) {
        setOtpStatus("verified");
        formRef.current.setFieldError("otpChangeMobile", undefined);
      } else {
        formRef.current.setFieldError("otpChangeMobile", "OTP does not match");
        formRef.current.setFieldTouched("otpChangeMobile", true, false);
      }
    });
  }

  const validate = (values) => {
    const errors = {};
    if (isValidMobileNo(values.currentMobile) !== "none") {
      errors.currentMobile = isValidMobileNo(values.currentMobile);
    }

    if (showChangeMobile) {
      if (isValidMobileNo(values.newMobile) !== "none") {
        errors.newMobile = isValidMobileNo(values.newMobile);
      }
      if (
        values.currentMobile === values.newMobile &&
        values.currentMobile !== ""
      ) {
        errors.newMobile = "Both new and old Mobile can't be same";
      }
      if (values.currentMobile !== info.mobile) {
        errors.currentMobile = "Current Mobile Number Incorrect";
      }
    }

    if (otpStatus === "sent" && values.otpChangeMobile.length === 6) {
      runMatchOtp(values.otpChangeMobile, showChangeMobile ? values.newMobile : values.currentMobile)
    }

    if (otpStatus !== "verified" || values.otpChangeMobile.length !== 6) {
      errors.otpChangeMobile = "OTP does not match";
    }
    return errors;
  };

  const reverifyMobile = async () => {
    doActualPostRequest("reverifystatus", null, null, {
      identifier: showChangeMobile ? formRef.current.values.newMobile : formRef.current.values.currentMobile,
      mode: "web",
      username: info.username,
      servicename: "reverifyem",
    }).then(() => {
      console.log("here")
      sessionStorage.setItem("reverifyMobile", "")
      closeModal()
      callback({ name: "Mobile", status: true })
    }).catch(() => {
    })
  }

  const handleSubmit = (_) => {
    reverifyMobile()
  };

  //otp related section stsrts here
  const askOtp = async () => {
    let returnValue = "";
    returnValue = getClientIpAddress().then(async (ipClient) => {
      return axios
        .post(
          `${BASE_ROUTE}/getotp`,
          {
            servicename: "reverifyem",
            identifier: showChangeMobile ? formRef.current.values.newMobile : formRef.current.values.currentMobile,
            mode: "web",
            ipaddress: ipClient,
            username: info.username
          }
        )
        .then((resp) => resp)
        .catch((error) => error.response);
    })

    return returnValue;
  };

  const sendOtp = () => {
    askOtp().then((res) => {
      console.log(res);
      if (res.status !== 200) {
        formRef.current.setFieldTouched("currentMobile",true,false)
        formRef.current.setFieldError("currentMobile", res.data.Message);
      } else if (res.status === 200) {
        formRef.current.setFieldError("newMobile", "");
        formRef.current.setFieldError("currentMobile", "");
        setOtpStatus("sent");
        timer.reset();
        timer.start();
      }
    });
  };

  const otpBtnListener = () => {
    if (otpStatus === "unsent") {
      sendOtp();
    } else if (otpStatus === "sent") {
      formRef.current.setFieldValue("otpChangeMobile", "", false);
      formRef.current.setFieldTouched("otpChangeMobile", false, false);
      formRef.current.setFieldError("otpChangeMobile", "");
      setOtpStatus("unsent");
    } else if (otpStatus === "verified") {
      setOtpStatus("unsent");
      formRef.current.setFieldValue("otpChangeMobile", "", false);
      formRef.current.setFieldTouched("otpChangeMobile", false, false);
      formRef.current.setFieldError("otpChangeMobile", "");
    }
  };

  const isAllowedToEnableChangeMobile = (oldMobile, newMobile) => {
    if (isValidMobileNo(oldMobile) === "none" && isValidMobileNo(newMobile) === "none") {
      return oldMobile !== newMobile && info.mobile === oldMobile;
    } else {
      return false;
    }
  }

  const isAllowedToEnableOtp = (oldMobile) => {
    // return false
    if (isValidMobileNo(oldMobile) === "none" && showChangeMobile === false) {
      return true;
    } else return false;
  };

  useEffect(() => {
    setOtpStatus("unsent")
    resetErrors()
  }, [showChangeMobile])

  
  useEffect(()=>{
    if(otpStatus === "verified"){
      formRef.current.handleSubmit() 
    }
  },[otpStatus])
  //otp related secton ends here
  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(formik) => (
        <Form>
          <div>
            <PaddedModal closeModal={() => closeModal(false)} open={open} size={paddedModalSizeConstatns["2xl"]}>
              <div className="w-full h-full flex flex-col">
                <h2 className="font-semibold text-pink-primary text-lg text-center ">Re-verify Mobile</h2>
                <hr className="bg-pink-primary h-1 mb-4" />
                <div className="text-center font-bold text-xs text-blue-error">
                  {formik.touched.newMobile && formik.errors.newMobile ? formik.errors.newMobile :
                    formik.touched.currentMobile && formik.errors.currentMobile ? formik.errors.currentMobile :
                      formik.touched.otpChangeMobile && formik.errors.otpChangeMobile ? formik.errors.otpChangeMobile : null
                  }
                </div>
                <div className="grid grid-cols-12 gap-2 w-full items-center">
                  <span className="inline-block text-gray-primary col-span-3 text-xs lg:text-sm">
                    Mobile
                  </span>
                  {/* current Mobile number */}

                  <WithTextInput
                    iconClasses="text-xxs font-semibold"
                    disable={true}
                    numbersOnly={true}
                    type="tel"
                    Id="currentMobile"
                    name="currentMobile"
                    val={formik.values.currentMobile}
                    change={(value) =>
                      formik.setFieldValue("currentMobile", value, true)}
                    blur={formik.handleBlur}
                    maxlen={10}
                    exClasses="text-gray-primary "
                    fClasses="col-span-7"
                    text=""
                    innerClasses="h-[28px] !text-sm"
                    textClick={() => setShowChangeMobile((prev) => !prev)}
                  />

                  <Button
                    exClasses="w-[3rem] col-span-1 h-[30px]"
                    text="OTP" 
                    dis={
                      !isAllowedToEnableOtp(formik.values.currentMobile)
                    }
                    click={() => sendOtp(formik)}
                  />
                  <a
                    className="text-pink-primary col-span-full text-end cursor-pointer text-xs mx-24 font-semibold whitespace-nowrap"
                    onClick={() => setShowChangeMobile((prev) => !prev)}>Change Mobile</a>

                  <Show when={showChangeMobile}>
                    <span className="mt-2 text-gray-primary col-span-3 text-xs lg:text-sm mb-2 whitespace-nowrap">New Mobile</span>
                    {/* new Mobile number */}
                    <Input
                      extraClasses="col-span-7"
                      Id="newMobile"
                      maxlen={10}
                      dis={
                        otpStatus === "sent" || otpStatus === "verified"
                      }
                      iType="tel"
                      numbersOnly={true}
                      override={{ height: "28px", fontSize: "14px" }}
                      name="newMobile"
                      val={formik.values.newMobile}
                      change={(values) =>
                        formik.setFieldValue("newMobile", values, true)
                      }
                      blurFunction={formik.handleBlur}
                    />
                    <Button
                      exClasses="w-[3rem] col-span-1 h-[28px]"
                      text={otpStatus !== "unsent" ? <i className="fa-regular fa-pen-to-square text-white w-5 "></i> : "OTP"}
                      dis={
                        !isAllowedToEnableChangeMobile(formik.values.currentMobile, formik.values.newMobile)
                      }
                      click={() => otpBtnListener()}
                    />
                  </Show>

                  {/* otp section */}
                  {otpStatus === "unsent" || otpStatus === "verified" ? null : (
                    <>
                      {/*<div className="flex gap-2 w-28 items-center col-span-full">*/}
                      <span className="text-gray-primary col-span-3 text-xs lg:text-sm">OTP</span>
                      <Input
                        extraClasses="w-24 col-span-4"
                        override={{ height: "28px", fontSize: "14px" }}
                        Id="otpChangeMobile"
                        numbersOnly={true}
                        maxlen={6}
                        iType="tel"
                        name="otpChangeMobile"
                        val={formik.values.otpChangeMobile}
                        change={(values) =>
                          formik.setFieldValue("otpChangeMobile", values, true)
                        }
                      />
                      <span className="col-span-5 text-xxs lg:text-sm text-center">
                        {timer.time === 0 ? (
                          <Button
                            exClasses="w-[6rem] col-span-1 h-[28px] text-xxs whitespace-nowrap"
                            click={() => {
                              formik.setFieldValue("otpChangeMobile","",false)
                                sendOtp(formik)
                            }}
                            text="Resend OTP"
                          />
                        ) : (
                          `${Math.floor(timer.time / 60)}:${timer.time % 60}`
                        )}
                      </span>
                      {/*</div>*/}
                    </>
                  )}
                  {/*
                  <Button
                    exClasses="col-span-full"
                    text="Submit"
                    type="submit"
                    click={formik.handleSubmit}
                  />
                  */}
                </div>
              </div>
            </PaddedModal>
          </div>
        </Form >
      )}
    </Formik >
  );
};

export default ReVerifyMobile;
