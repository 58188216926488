//header for logged user
import "../css/loggedHeader.css";
import "../App.css";
import React, { useEffect, useState } from "react";
import UserMenu from "./userMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DropMenu from "./dropMenu";
import { useDispatch } from "react-redux";
import { clearAll } from "../app/features/prepaidPlansSlice";
import { USER_LOGGED_OUT_REASON, IDLE_LOGOUT_KEY, paddedModalSizeConstatns } from "./constants"
import { useIdleTimer } from "react-idle-timer";
import { useLogout } from "./customHooks/useLogout";
import ReVerifyEmail, { Show } from "./userpages/ReVerifyEmail";
import ReVerifyMobile from "./userpages/ReVerifyMobile";
import { isValidEmail, isValidMobileNo } from "./usefullFunctions";
import PaddedModal from "./modals/PaddedModal";
import Button from "./button";
import { useLoginModal } from "./userpages/useLoginModal";

// timeout: 1000 * 60 * 15,
export const HeaderLogged = () => {
  const { logUserOut } = useLogout();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const [showReverifyEmail, setShowReverifyEmail] = useState(false)
  const [showReverifyMobile, setShowReverifyMobile] = useState(false)
  // const [showKycNotDone,setShowKycNotDone] = useState(false)
  const [message, setMessage] = useState({ name: "", status: false })
  const [openModal, setOpenModal] = useState(false)
  const {info} = useLoginModal()
  const { start } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      sessionStorage.setItem(IDLE_LOGOUT_KEY, USER_LOGGED_OUT_REASON)
      logUserOut()
    },
  });

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (isValidEmail(sessionStorage.getItem("reverifyEmail")) === "none") {
      setTimeout(() => {
        setShowReverifyEmail(true)
      }, 700)
    } else if (isValidMobileNo(sessionStorage.getItem("reverifyMobile")) === "none") {
      setTimeout(() => {
        setShowReverifyMobile(true)
      }, 700)
    }
  })

  return (
    <>
      <header className="print:hidden">
        <div className=" width mx-auto flex items-center mt-[3px] pl-1 ">
          <DropMenu />

          {/*logo declaration   */}
          <div
            className="pl-[2px] pr-4 logo mr-auto relative  mt-[1px] ml-2  small-logo shrink-[0.2] cursor-pointer"
            onClick={() => {
              dispatch(clearAll());
              navigate("/home");
            }}
            tabIndex={0}
            title="RechargeAXN"
          ></div>
          {/* </Link> */}

          <div className="nav flex  gap-[0.9rem] items-center flat-menu hidden mlg:flex text-pink-primary">
            <Link to="/home/referandearn">
              <div className="rewards text-sm">Refer</div>
            </Link>
            <Link to="/home/rewards">
              <div className="rewards text-sm">Rewards</div>
            </Link>
            <Link to="/home/offers">
              <div className="offers text-sm">Offers</div>
            </Link>
            <Link to="/home/suggestions">
              <div className="suggestion text-sm">Complain</div>
            </Link>

            <div className="notification">
              <button
                className="flex px-1 items-center justify-center"
                id="notifiy"
              >
                <i className=" fa-regular fa-bell text-xl" area-hidden="true"></i>
              </button>

              <div className="notificaton-box  absolute flex flex-col items-center justify-center w-65 right-32">
                <div className="item bg-white px-4 py-2 border">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Veritatis, iste.
                </div>
                <div className="item bg-white px-4 py-2 border">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis,
                  ea.
                </div>
                <div className="item bg-white px-4 py-2 border">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                  architecto
                </div>
              </div>
            </div>

            <Link to="/home/wallet" title="Wallet">
              <span className="flex items-center justify-center ">
              {
//                 <button className="flex w-6 h-6 items-center justify-center scale-95 mr-2"></button>
              }
                <span className="text-sm">Wallet</span>
              </span>
            </Link>
            <UserMenu showInSmall={true} />
          </div>

          <div className="notification show-in-small mlg:hidden ml-[0.9rem]  mt-[6px] scale-small small-btn">
            <button
              className="flex px-1 items-center justify-center"
              id="notifiy"
            >
              <i
                className=" fa-regular fa-bell text-[2rem] text-pink-primary"
                area-hidden="true"
              ></i>
            </button>

            <div className="notificaton-box  absolute flex flex-col items-center justify-center w-64 right-32  ">
              <div className="item bg-white px-4 py-2 border">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Veritatis, iste.
              </div>
              <div className="item bg-white px-4 py-2 border">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis,
                ea.
              </div>
              <div className="item bg-white px-4 py-2 border">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
                architecto
              </div>
            </div>
          </div>

          <Link to="/home/wallet">
            <span className="flex items-center justify-center show-in  sm:scale-[.85] flex-col lg:flex-row md:mt-2 lg:mt-auto">
            {
//               <button className="flex w-[2rem] h-[2rem]  items-center justify-center sm:scale-[.9] show-in-small mlg:hidden ml-[0.9rem] small-margin  mt-[6px] scale-small sm-font-sm small-btn"></button>
            }
              <span className="hide-at-large text-sm">Wallet</span>
            </span>
          </Link>

          <UserMenu />
        </div>
        {/*
        !showIdleModal ? null : (
        <IdleModal
          open={showIdleModal}
          setOpen={() => setOpenIdleModal(false)}
          message={`You are idle for a while, do you still want to stay 'Signed In'? You will be logged out automatically after 10s`}
        />
      )*/}
      </header>
      <section className="hidden print:block h-16 w-auto p-2 flex w-full justify-center items-center">
        <img src="/images/RechargeLogo.jpeg" className="h-10 mx-auto" />
      </section>
      <Show when={showReverifyEmail}>
        <ReVerifyEmail
          open={showReverifyEmail}
          callback={(val) => {
            setMessage(val)
            setOpenModal(true)
          }}
          closeModal={() => setShowReverifyEmail(false)}
        />
      </Show>

      <Show when={showReverifyMobile}>
        <ReVerifyMobile
          open={showReverifyMobile}
          callback={(val) => {
            setMessage(val)
            setOpenModal(true)

          }}
          closeModal={() => setShowReverifyMobile(false)}
        />

      </Show>
      <PaddedModal
        size={paddedModalSizeConstatns.md}
        open={openModal}
        closeModal={() => setOpenModal(false)}
      >
        <div className="h-full w-full flex justify-center items-center">
          <div className="text-center text-xl text-green-info mt-2">
            <p className="text-blue-error text-sm">
              {message.name} verification completed
            </p>
            <p className="text-blue-error text-sm">
              Thank You
            </p>
            <div className="flex justify-center mt-2">
                <Button text="Ok" click={()=>setOpenModal(false)} exClasses="px-4"/>
            </div>
          </div>
        </div>
      </PaddedModal>
    </>
  );
}
