export const ENV = localStorage.getItem("env");
export const OTP_SUCCESS = "OTP matched & verified";
export const OTP_SENT = "OTP generated";
export const FAIL_STAUS = "Error";
export const EMAIL_ALREADY_REGISTERED = "Email is already registered";
export const PHONE_ALREADY_REGISTERED = "Mobile is already registered";
export const SUCCESS = "Success";
export const EMAIL_MOBILE_NOT_REGISTERED =
  "User mobile/email is not registered";
export const OTP_NOT_VERIFIED = "OTP Not Verified";
export const RESET_PASSWORD_COMPLETE = "Reset password completed";
export const PASS_CHANGE_COMPLETE =
  "Password changed successfully. Please logout and login again";
export const MID =
  ENV === "PROD" ? "irBGEr01887325618666" : "wOaaJx89521528909942";
export const MID_DEV = "wOaaJx89521528909942";
export const URL_PROD =
  ENV === "PROD" ? "securegw.paytm.in" : "securegw-stage.paytm.in";
export const URL_DEV = "securegw-stage.paytm.in";
export const WEBSITENAME = ENV === "PROD" ? "DEFAULT" : "WEBSTAGING";
export const WEBSITENAME_DEV = "WEBSTAGING";

export const SERVICE_NAME = {
  PREPAID_MOBILE: "Prepaid Recharge",
  POSTPAID_MOBILE: "Postpaid Recharge",
  DTH_RECHARGE: "DTH Recharge",
  ELECTRICITY: "Electricity Bill Pay",
  BROADBAND: "Broandband Bill Pay",
  GAS_LPG:"LPG Bill Pay",
  WATER:"Water Bill Pay",
  FASTAG:"FASTag Recharge",
  INSURANCE: "Insurance Bill Pay",
  GIFT_CARD:"Giftcard Recharge",
  WALLET: "wallet",
};
export const USER_LOGGED_OUT_REASON = "idleLoggedOut";
export const IDLE_LOGOUT_KEY = "idle";
export const COUPON_CODE_APPLY_MESSAGES = {
  APPLIED: "Coupon Applied Successfully",
  NOT_APPLIED: "Coupon removed successfully",
  NONE: ""

};

export const COUPON_APPLY_STATE = {
  BLANK: null,
  APPLIED: "applied",
  NOT_APPLIED: "not_applied",
  ERROR: "error"
};

export const REFER_CODE = "refer_code";
export const months = [
  "Jan"
  , "Feb"
  , "March"
  , "April"
  , "May"
  , "June"
  , "July"
  , "Aug"
  , "Sept"
  , "Oct"
  , "Nov"
  , "Dec"
]

export const paddedModalSizeConstatns = {
  md:"md",
  sm:"sm",
  lg:"lg",
  xl:"xl",
  "2xl":"2xl"
}
export const delhiAddress = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2211182195388!2d77.03037871415049!3d28.62313428242151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0540b1bd5c8b%3A0x6e6cde84b9e84c55!2sd-7a%20bhagwati%20garden%20extn%20uttam%20nagar%20new%20delhi%20110059!5e0!3m2!1sen!2sus!4v1671119501415!5m2!1sen!2sus"
export const address = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.7958159698815!2d85.19822265305842!3d25.60746146118144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed5f3862ee8b53%3A0x77c6669fe5334a81!2sKhara%20Kuan%2C%20Sadikpur%2C%20Patna%2C%20Bihar%20800007!5e0!3m2!1sen!2sin!4v1685453336929!5m2!1sen!2sin"
export const SERVICE_NAME_CODE = {
  PREPAID_MOBILE: "prepaidpay",
  POSTPAID_MOBILE: "postpaidpay",
  DTH_RECHARGE: "dthpay",
  ELECTRICITY: "electricpay",
  BROADBAND: "broadbandpay",
  GAS_LPG: "gaspay",
  WATER: "waterpay",
  FASTAG: "fastagpay",
  INSURANCE: "insurancepay",
  GIFT_CARD: "giftcardpay",
}


