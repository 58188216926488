import React from "react";
import Box from "@mui/system/Box";
import { FcInfo } from "react-icons/fc";

const PleaseWaitModal = ({ timer = 0,message="Please wait while we are processing your payment request" }) => {
  return (
    <div className="w-full px-1 text-pink-primary rounded border-2">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          {" "}
          <FcInfo size={"2rem"} />{" "}
        </span>
        <p className="flex-1 text-sm px-4 text-blue-700">
	  {message}
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          fontSize: "3rem",
          width: "100%",
          alignItems: "center",
          padding: ".2rem 1rem",
        }}
      >
        <span className="mx-auto">{Math.round(timer / 1.5)}</span>
      </Box>
    </div>
  );
};

export default PleaseWaitModal;
