import React from "react";
import LeftNav from "./leftNav";
import RightNav from "./rightNav";
import ImageGallery from "react-image-gallery";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
// import { useState } from "react";
import { BASE_ROUTE, IMG_BASE } from "../components/routes";

const transform = (data) => {
  let toReturn = [];
  toReturn = Object.keys(data).map((element) => ({
    original: `${IMG_BASE}${data[`${element}`]}`,
  }));
  return [...toReturn];
};
export const Crosel = () => {
  const navigate = useNavigate();
  // let [images, setImages] = useState([]);
  let called = (e) => {
    console.log(e.target.src);
    let sub = e.target.src.substring(
      e.target.src.lastIndexOf("/") + 1,
      e.target.src.length
    );
    console.log(sub == "UEN3qUTSqZUqu24T.jpg");
    if (sub === "UEN3qUTSqZUqu24T.jpg") {
      navigate("/home/rewards");
    } else {
      navigate("/home/offers");
    }
    console.log(e.target);
  };

  const { isLoading, error, data, isFetched, refetch } = useQuery(
    "sliderList",
    () => axios.get(`${BASE_ROUTE}/slider`,{
      params:{
        mode:"web"
      }
    }).then((res) => transform(res.data)),
    { staleTime: Infinity }
  );
  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  return (
    <ImageGallery
      items={data}
      onClick={called}
      autoPlay={true}
      infinite={true}
      showBullets={true}
      showFullscreenButton={false}
      showPlayButton={false}
      showNav={true}
      slideInterval={8000}
      slideDuration={250}
      loading="eager"
      //       lazyLoad={true}
      additionalClass="banner"
      renderLeftNav={function (onClick, disabled) {
        return <LeftNav onClick={onClick} disabled={disabled} />;
      }}
      renderRightNav={function (onClick, disabled) {
        return <RightNav onClick={onClick} disabled={disabled} />;
      }}
    />
  );
};
