import React from "react";

const Danger = ({ exClasses = " ", content = " ", style = {} ,cms=true}) => {
  return (
    <div
      data-cms={cms === true?true:null}
      dangerouslySetInnerHTML={{ __html: content }}
      className={`flex flex-col justify-left py-1 h-96 overflow-y-auto text-gray-primary show prose ${exClasses}`}
      style={style}
    ></div>
  );
};

export default Danger;
