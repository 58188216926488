import React from "react";
import { useLocation } from "react-router-dom";
import "../css/footer.css";
import { useDispatch } from "react-redux";
import { clearAll } from "../app/features/prepaidPlansSlice";
import CustomLink from "./utility/CustomLink";

export const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isReferPage = location.pathname.includes("/signupreferred");

  return (
    <footer className="w-full">
      <div className="flex items-center justify-center  text-white   my-2 w-full">
        <ul className="flex flex-wrap justify-center items-center ">
        <div className="flex flex-wrap justify-center items-center relative border border-gray-400 p-[2px] rounded pt-2 px-[6px]">
           <label className="absolute -top-[0.5rem] left-2 bg-white text-gray-400 text-[0.6rem]">PG Partner</label>
          <li className="mr-[10px] mb-1 w-[50px] flex items-center h-fit">
            <img src="/images/master-card.svg" alt="" className="w-full" />
          </li>
          <li className="mr-[10px] w-[50px] flex items-center h-fit">
            <img src="/images/pci-dss.svg" alt="" className="w-full" />
          </li>
          <li className="mr-[10px]  w-[50px] flex items-center h-fit">
            <img src="/images/verified-by-visa.svg" alt="" className="w-full" />
          </li>
          <li className=" w-[50px] flex items-center h-fit">
            <img src="/images/RuPay_Logo.svg" alt="" className="w-full" />
          </li>
        </div>
          <a
            className="mr-[10px]  w-[120px] flex items-center"
            href='https://play.google.com/store/apps/details?id=in.recharge.axn&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            target="_blank"
            rel="noopener noreferrer"
           >
           <img
           className="w-full"
             alt='Get it on Google Play'
             src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
          </a>
        </ul>
      </div>

      <div className="flex items-center justify-center bg-primary text-white  p-[9px]  w-full">

      <ul className={`flex flex-wrap justify-center  list-none `}>
        <CustomLink to="/home/aboutus" disbale={isReferPage} >
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary ">
            About Us
          </li>
        </CustomLink>

        <CustomLink to="/home/faq" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary ">
            FAQ
          </li>
        </CustomLink>
        <CustomLink to="/home/contactus" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
            Contact Us
          </li>
        </CustomLink>

        <CustomLink to="/home/terms" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
            Terms & Conditions
          </li>
        </CustomLink>
        <CustomLink to="/home/privacypolicy" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
            Privacy Policy
          </li>
        </CustomLink>
        <CustomLink to="/home/refundpolicy" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
            Refund Policy
          </li>
        </CustomLink>

        <CustomLink to="/home/delete-account/" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
             Delete Account
          </li>
        </CustomLink>

        <CustomLink to="/admin/index/rechaxn/login" disbale={isReferPage}>
          <li className="inline-block py-1 px-2 text-[10px] text-tertiary">
             Go to Admin
          </li>
        </CustomLink>
      </ul>
      </div>

      <div className="flex items-center justify-center border-0 bg-white  px-[9px] text-[12px] w-full text-pink-primary">
        <p className="text-center small-copyright">
          Copyright © { new Date(Date.now()).getFullYear()
          } &nbsp;
          <CustomLink
            to="/home"
            onClick={() => {
              dispatch(clearAll());
            }}
            className="hover:text-black"
            disbale={isReferPage}
          >
            RechargeAXN
          </CustomLink>
          . All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

